$mrnda-navigation-height: $base-spacing-unit*6;

//-- Main Navigation --------------------------------------------------
%navigation-base {
  height: $mrnda-navigation-height;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 $base-spacing-unit*2;
  // position: fixed;
}
.navbar-brand > img {
  width: 160px;
}

.mrnda-navigation {
  @extend %navigation-base;
  background-color: var(--primary-color);
  z-index: 4;
  min-height: 60px;
}

.mrnda-primary-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 60%;
}

.mrnda-primary-navigation__item {
  display: block;
  float: left;
  margin-right: $base-spacing-unit*4;
  font-weight: $base-font-weight-bold;

  &:last-child {
    margin-right: 0;
  }
}

.mrnda-primary-navigation__item--logo {
  // margin-right: $base-spacing-unit;
  // // background: rgba($global-white,.4);
  // height: $base-spacing-unit*4;
  // color: $global-brand-contrast;
  // // filter: brightness(1.8);
  margin-right: $base-spacing-unit*4;
  height: $base-spacing-unit*3;
  color: $global-brand-contrast;

  & img {
    height: $base-spacing-unit*3;
    width: auto;
  }
}

.mrnda-primary-navigation__link {
  color: $global-brand-contrast;

  &:hover {
    color: rgba($global-brand-contrast,.4);
  }
}

.mrnda-secondary-navigation {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 40px;
  width: auto;
}

.mrnda-secondary-navigation__item {
  display: block;
  float: right;
  margin-right: $base-spacing-unit*2;
  font-weight: $base-font-weight-bold;

  &:last-child {
    margin-right: 0;
  }
}



//-- Sub Navigation ---------------------------------------------------
.mrnda-sub-navigation {
  @extend %navigation-base;
  background-color: var(--primary-background-color);
  box-shadow: 0 1px 0 0 var(--border-color);
  // top: $mrnda-navigation-height;
  top: 0px;
  z-index: 3;
  min-height: 60px;
}

.mrnda-sub-navigation__title {
  float: left;
  width: 20%;
}

.mrnda-sub-navigation__options {
  float: right;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mrnda-sub-navigation__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mrnda-sub-navigation__list-item {
  @extend %margin-2x--left;
  display: inline-block;

  &:first-child {
    margin-left: 0;
  }
}


@media print {
  .mrnda-navigation,
  .mrnda-sub-navigation,
  .mrnda-navigation *,
  .mrnda-sub-navigation * {
      display: none !important;
  }
}
