.mrnda-divider {
  display: block;
  width: 100%;
  margin: 0;
  border: none;
  border-top: 1px solid $base-divider-color;
}

.mrnda-divider--sidebar {
  display: block;
  width: calc(100% - $base-spacing-unit*2);
  margin: 0;
  left: $base-spacing-unit*2;
  position: relative;
  border: none;
  border-top: 1px solid $base-divider-color;
}

.mrnda-dot-separator {
  font-size: 11px;
  position: relative;
  top: -1px;
  margin: 0px 5px;
}
