.mentions {
    // margin: 1em 0;
}

.comment-mentions--multiLine .comment-mentions__control {
    background-color: var(--primary-background-color);
    border-radius: 0.25rem;
}

.reply-mentions--multiLine .reply-mentions__control {
    background-color: var(--primary-background-color);
    border-radius: 0.25rem;
}

.comment-mentions--multiLine .comment-mentions__highlighter {
    // border: 1px solid transparent;
    // padding: 7px;
    padding: .375rem .75rem;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    min-height: 50px;
}

.reply-mentions--multiLine .reply-mentions__highlighter {
    // border: 1px solid transparent;
    padding: .375rem .75rem;
    min-height: 52px;
    font-size: 1rem !important;
    line-height: 1.5 !important;
}

.comment-mentions--multiLine .comment-mentions__input {
    border: none;
    padding: .375rem .75rem;
    outline: 0;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    border-radius: 0.25rem;
    color: var(--primary-text-color);
    transition: border-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary-color-shade-2),
            0 0 0 1px var(--primary-color-shade-2) inset;
        background-color: var(--primary-background-color);
        color: var(--secondary-text-color);
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--secondary-text-color);
        opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--secondary-text-color);
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--secondary-text-color);
    }
}

.reply-mentions--multiLine .reply-mentions__input {
    border: none;
    padding: .375rem .75rem;
    outline: 0;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    border-radius: 0.25rem;
    color: var(--primary-text-color);
    transition: border-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary-color-shade-2),
            0 0 0 1px var(--primary-color-shade-2) inset;
        background-color: var(--primary-background-color);
        color: var(--secondary-text-color);
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--secondary-text-color);
        opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--secondary-text-color);
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--secondary-text-color);
    }
}



.comment-mentions__suggestions, .reply-mentions__suggestions {
    position: absolute;
    z-index: 1;
    background-color: var(--secondary-background-color) !important;
    // background-color: red !important;
    margin-top: 14px;
    min-width: 100px;
    left: 20px;
    top: 8px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
}

.comment-mentions__suggestions__list, .reply-mentions__suggestions__list {
    // background-color: var(--primary-background-color);
    // border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
    
}

.comment-mentions__suggestions__item, .reply-mentions__suggestions__item{
    color: var(--primary-text-color);
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.comment-mentions__suggestions__item--focused, .reply-mentions__suggestions__item--focused {
    background-color: var(--control-border-color);
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: white;
    pointer-events: none;
    background-color: var(--primary-color);
}
