.breadcrumbs__container {
    display: inline-flex;
    padding: 10px;
}

.breadcrumbs__container__item {
    font-size: 12px !important;
    color: #737373;
    opacity: 0.8;
    list-style: none;
    display: flex;
    align-items: center;
}

.breadcrumbs__separator-icon {
    padding: 0px 7px 0px 7px;
    margin-top: 2px;
}

.breadcrumbs__container li a:hover {
    cursor: pointer !important;
    color: black !important;
}
