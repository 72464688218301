.custom-email-template-signature {
    width: 100%;
    height: 100%;
    margin-top: 6px;
    max-width: 600px;
    .richText {        
        border: 1px solid var(--control-border-color);
        border-radius: 6px;
        color: var(--secondary-text-color);
        background-color: var(--secondary-background-color);
    }

    .richText .richText-editor {
        background-color: var(--secondary-background-color);
        border-radius: 6px;
        border: none;
        height: 120px;
    }
    
    .richText .richText-editor:focus {
        border-left: #3498db solid 0px;
    }

    

    
}
