//-- Reset Browser Form appearance ------------------------------------
%reset-form-appearance {
  -webkit-appearance: none;
  -moz-appearance:none;
  appearance: none;
  box-sizing:content-box;
}

//-- Remove outlines on form elements ---------------------------------
%reset-outline {
  outline: none
}
