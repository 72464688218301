$mrnda-sidebar-width: $base-spacing-unit*40;

%sidebar-base {
  width: $mrnda-sidebar-width;
  background: var(--primary-background-color);
  position: relative;
  z-index: 1;
  height: 100vh;
  padding-top: $mrnda-navigation-height;
  top: 0;
}

.mrnda-sidebar--left {
  @extend %sidebar-base;
  box-shadow: -1px 0 0 0 var(--border-color) inset;
  left: 0;
  height: 100%;
  padding-top: 0px;
}

.mrnda-sidebar--right {
  @extend %sidebar-base;
  box-shadow: 1px 0 0 0 $base-divider-color inset;
  right: 0;
}

.mrnda-sidebar--subnav-open {
  
}

.mrnda-sidebar__content {
  position: sticky;
  top: 0;
  overflow: scroll;
  height: calc(100% - 20px);
  -webkit-overflow-scrolling: touch;
}

.mrnda-sidebar__footer {
  width: calc(100% - 1px);
  height: $base-spacing-unit*4;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 -1px 0 $base-divider-color;

  .mrnda-sidebar--right & {
    left: 1px;
  }
}

.mrnda-sidebar__footer-content {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $base-spacing-unit*1.5;
}
