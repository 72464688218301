.mrnda-tag--outline {
  @extend %typography--small;
  text-transform: uppercase;
  box-shadow: 0 0 0 1px rgba($global-black,.4);
  padding: $base-spacing-unit*.1 $base-spacing-unit*.5;
  border-radius: $base-border-radius;
  border-radius: $base-border-radius--small;
  position: relative;
  top: -$base-spacing-unit*.1;
}
