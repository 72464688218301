.mrnda-video-transcription {
    // padding: 20px 20px 20px 20px;
    height: calc(100vh - 10px);
    background-color: var(--primary-background-color);
    &__container {
        // top: 60px;
        position: relative;
        display: flex;
        flex-direction: column;
        // width: 1430px;
        // margin: 0px 20px 0px 0px;
        height: 100%;
    }
}

.mrnda-video-transcription__player-area {
    display: flex;
    flex-direction: column;
    width: calc(100% - 350px);
    height: calc(100% - 218px);
}
.mrnda-video-transcription__sidebar {
    padding: 20px 10px;
    flex: 1 0 345px;
    overflow: hidden auto;
    margin: 0 0 0 1px;
    background: var(--secondary-background-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #dadce0;
    &__transcriptions {
        overflow: hidden auto;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 12px;
        &--item {
            padding: 8px;
            margin: 0px 0px 8px 0px;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid transparent;
            color: var(--secondary-text-color);
            &:hover {
                cursor: pointer;
                border: 0.1px solid #444444;
            }
            &.selected {
                background: #0091ff24;
                border: 1px solid var(--primary-color);
            }
        }
        &--time-range {
            background: var(--primary-color);
            padding: 4px;
            border-radius: 4px;
            margin: 0 4px 0 0;
            color: white;
        }
        &--value {
        }
    }
}
