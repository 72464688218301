// .sign-in-box-wrapper {
//   width: $base-spacing-unit*35;
// }

// .sign-in-box {
//   width: 100%;
// }

.tenant-logo{
  // background: #0091ffb0;
  padding: 13px;
  border-radius: 4px;
  img {
    width: 330px;
  }
}
