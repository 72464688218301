//-- Helpers ----------------------------------------------------------
@function padding-get-size($key) {
  $sizes: map-get($padding-config, sizes);
  @return map-get($sizes, $key);
}

//-- Config -----------------------------------------------------------
$padding-spacing:  $base-spacing-unit;
$padding-key-_5x:  "-_5x";
$padding-key-1x:   "-1x";
$padding-key-1_5x: "-1_5x";
$padding-key-2x:   "-2x";
$padding-key-3x:   "-3x";
$padding-key-4x:   "-4x";

$padding-config: (
  modifiers: top bottom left right,
  variations: $padding-key-_5x $padding-key-1x $padding-key-1_5x $padding-key-2x $padding-key-3x $padding-key-4x,
  sizes: (
    $padding-key-_5x:  $padding-spacing*0.5,
    $padding-key-1x:   $padding-spacing*1,
    $padding-key-1_5x: $padding-spacing*1.5,
    $padding-key-2x:   $padding-spacing*2,
    $padding-key-3x:   $padding-spacing*3,
    $padding-key-4x:   $padding-spacing*4
  )
);

//-- Main -------------------------------------------------------------
@each $variation in map-get($padding-config, variations) {
  $size: padding-get-size($variation);
  $modifiers: map-get($padding-config, modifiers);

  %padding#{$variation},
  .padding#{$variation} {
    padding: $size;
  }

  %padding#{$variation}--sides,
  .padding#{$variation}--sides {
    padding-left:  $size;
    padding-right: $size;
  }

  %padding#{$variation}--ends,
  .padding#{$variation}--ends {
    padding-top:    $size;
    padding-bottom: $size;
  }

  @each $modifier in $modifiers {
    %padding#{$variation}--#{$modifier},
    .padding#{$variation}--#{$modifier} {
      padding-#{$modifier}: $size;
    }
  }
}
