%mrnda-btn,
.mrnda-btn {
  background: #ddd;
  display: inline-block;
  border: 0;
  border-radius: $base-border-radius;
  font-size: $base-font-size;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: $base-spacing-unit*4;
  padding: $base-spacing-unit $base-spacing-unit*1.5;
  line-height: $base-line-height;
  background-color: var(--primary-color);
  color: $global-white;
  position: relative;
  cursor: pointer;
  transition: background-color .3s ease;
  user-select: none;

  &:hover {
    color: $global-white;
    background-color: lighten($global-brand, 10%);
  }

  &:focus {
    outline: none;
  }

  &:active {
    top: 2px;
  }
}

.mrnda-btn--full {
  width: 100%;
}

.mrnda-btn--secondary {
  @extend %mrnda-btn;
  background-color: transparent;
  box-shadow: 0 0 0 2px var(--primary-color) inset;
  color: var(--primary-text-color);

  &:hover {
    color: var(--primary-color);
    background-color: rgba($global-brand,.15);
  }
}

.mrnda-btn--caution {
  @extend %mrnda-btn;
  background-color: transparent;
  box-shadow: 0 0 0 2px rgba($global-caution,.8) inset;
  color: $global-caution;

  &:hover {
    color: $global-caution;
    background-color: rgba($global-caution,.15);
  }
}

button:disabled,
.mrnda-btn--disabled {
  background-color: var(--tertiary-background-color);
  color: var(--secondary-text-color);
  cursor: auto;
  pointer-events: none;

  &:hover {
    color: rgba($global-black,.65);
    background-color: rgba($global-black,.1);
  }

  &:active {
    top: 0;
  }
}


.mrnda-icon-btn {
  border: 0;
  width: $base-spacing-unit*3;
  height: $base-spacing-unit*3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .6;
  transition: opacity .3s ease, background-color .3s ease;
  // border-radius: $base-border-radius;
  background-color: var(--secondary-background-color);
  color : var(--secondary-text-color);
  &:hover {
    opacity: 1;
    background-color: rgba($global-black,.05);
  }

  &:active {
    box-shadow: 0 0 0 2px var(--primary-color);
  }

  &:focus {
    outline: none;
  }
}

.mrnda-icon-btn:disabled,
.mrnda-icon-btn--disabled {
  cursor: auto;
  pointer-events: none;
  opacity: .2;
  color: var(--secondary-text-color);
}
