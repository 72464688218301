.mrnda-video-editor-container--mobile {
    top: 60px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 85px);
}
.mrnda-video-editor-sidebar--mobile {    
    padding: 10px 0px 4px 0px;    
    overflow: hidden auto;        
    margin: 0 0 0 1px;
    @include neumorphic-card;
    height: 42%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(----border-color);
    &__header {
        flex-flow: row wrap;
        width: auto;
        &__container {
            display: flex;
            justify-content: space-between;
        }
        &__text {
            color: var(--primary-text-color);
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
            word-break: break-word;
        }
    }
    &__comments {
        overflow: hidden auto;
    }
    &__tabs {
        padding: 12px 0;
        &__item {
            margin-left: 0;
            margin-right: 20px;
            padding: 12px 0;
            cursor: pointer;
        }
    }
}
.mrnda-video-editor-area--mobile {
    height: 58%;
    margin: 0px 0px 0px 0px;
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-canvas-wrapper {
        transition: transform 0.3s ease;
    }
}


.mrnda-video-editor-area-toolbox--mobile {
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;  
    margin-bottom: 0;
    height: 76px;
    display: flex;
    align-items: center;
    &__header {        
        flex: 2;
        &__title {
            color: var(--primary-text-color);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 133.333% */         
        }
    }
}

.mrnda-video-editor-area-canvas-container--mobile {
    overflow: hidden !important;
    padding: 0;
    margin: 0;    
    flex : 1;
}

.mrnda-video-editor-area-selection--mobile {
    height: 100%;
    width: 100%;
    background: var(--primary-background-color); 
    position: absolute;  
    z-index: 2;       
    display: flex;
    justify-content: center;
    align-items: center;
}


.contributor-color-picker--mobile {
     max-width: 360px !important;
}

.contributor-color-picker__color-outer--mobile {
    height: 44px !important; 
    width: 44px !important;
    margin: 8px !important;    
}


.mrnda-video-editor-area-controls-buttons--mobile {    
    &--play-pause {
        margin-left: 0 !important;
        height: 52px !important;
        width: 52px !important;
        background-color: var(--tertiary-background-color) !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px !important;
        margin-right: 16px !important;
    }
    &--step {
        height: 36px !important;
        width: 36px !important;
        background-color: var(--tertiary-background-color) !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.hello-class {
    display: flex;
}

.mrnda-video-editor-area-controls--mobile {    
    overflow: hidden;
}


.comment--mobile {        
    background-color: var(--tertiary-background-color) !important;  
    border-radius: 8px !important;
    padding: 20px 0px 12px 0px !important;
    margin-bottom: 12px;
}

.comment__body--mobile {
    background-color: transparent !important;
    padding: 0 !important;
    padding-right: 20px !important;
}

.comment__metadata-separator--mobile {
    height: 1px;
    width: 100%;    
    position: absolute;       
}





.mrnda-video-editor-container--mobile {
    top: 60px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 85px);
}


.mrnda-document-editor-area--mobile {
    height: 58%;
    margin: 0px 0px 0px 0px;
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-canvas-wrapper {
        transition: transform 0.3s ease;
    }
}


.mrnda-document-editor-container--mobile {
    top: 60px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 85px);    
    overflow: hidden;
}