//-------
// Fonts
//-------
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,700');

//----------------
// Color Variables
//----------------
$black: #3d4852;
$blue: #55b6b7;
$green: #6cc08a;
$red: #ed655e;

$primary-text-color: $black;
$secondary-theme-color: $blue;
$primary-progress-bar-color: $red;
$secondary-progress-bar-color: $green;

//----------------
// App Styles
//----------------
.file-upload__modal {
    display: block;
    float: left;
    // width: 350px;
    // margin: 50px auto;
    padding: 25px 0;
    // border: 1px solid #ccc;
    border-radius: 0.3rem;
    background-color: var(--primary-background-color);
    // box-shadow: 0 4px 6px 0 rgba(#332e3a, 0.1);
}

.file-upload__modal-title {
    margin-bottom: 30px;
    color: $primary-text-color;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
}

.file-upload__error {
    display: none;
    margin: 0 25px 30px;
    color: $red;
    font-size: 14px;
}

.file__list {
    margin: 0 25px;
}

.file-upload__form {
    display: grid;
    align-content: center;
    justify-content: center;
    width: 400px;
    height: 250px;
    transition: all 0.3s;
    border: 2px dashed rgba($global-brand, 0.3);
    border-radius: .3rem;

    &:hover {
        border: 2px dashed var(--primary-color);
    }
}

.file__input {
    display: none;
}

.file__input-label {
    width: 150px;
    color: var(--secondary-text-color);
    font-size: 14px;
    letter-spacing: 1.3px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
}

.file__input-label-button {
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: 1.3px;
    line-height: 1.5;
    transition: all 0.3s;
    color: var(--primary-color);
    cursor: pointer;
    border: none;
    text-transform: uppercase;

    &:hover {
        color: rgba($global-brand, 0.3);
    }
}

.file-details__container {
    // display: grid;
    // grid-template-columns: 235px 20px;
    // padding-left: 15px;
    float: right;
    width: 50%;
    margin: 20px 0;
}
.file-details__container label{
    font-size: 9px;
    color: var(--secondary-text-color);
}

.file-output__list-item {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: end;
    margin: 0 auto 25px;
    list-style: none;
}

.file-output__list-item-close{
    color: var(--primary-text-color);
    opacity: .6;
    transition: all .3s;
    float: right;
}

.file-output__list-item-image {
    display: inline;
    // width: 30px;
    // height: 38px;
    opacity: 0.4;
    float: left;
    margin: 3px 28px 3px 10px;
    color: var(--icon-text-color);
}

.file-output__list-item-name {
    // margin-bottom: 10px;
    margin-left: 1px;
    // opacity: 0.8;
    color: var(--primary-text-color);
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 1.3px;
    line-height: 1.5;
    display: inline-block;
    width: 55%;
}
.file-output__list-item-status {
    float: right;
    margin: 0px -4px 0px 1px;
    color: #4eb3b4;
    border-color: #4eb3b4;
}
.file-output__list-item-status__label{
    margin: 0px 1px 0px 2px;
    color : var(--primary-text-color);
}
.file-output__list-item--is-complete {
    display: flex;
    align-self: center;
    width: 10px;
    height: 10px;
}
.file-output__list-item-footer__size {
  float: left;
  margin-left: 2px;
  color : var(--secondary-text-color);
}
.file-output__list-item-footer__time {
    float: right;
    margin-right: 2px;
}
.file-output__list-item-header {
    margin: 3px 3px 3px 0px;
}
.file-output__list-item-middle-area {
    margin: 2px 0px 0px 0px;
}
.file-output__list-item-footer {
    margin-top: 3px;
    margin-left: 58px;
    font-size: 11px;
    color: #3d4852;
}
