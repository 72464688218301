.tab-host {    
    display: flex;
    flex-direction: column;
}

.tab-content {       
}

.tab-header {
    margin-right: 16px;
    color: var(--secondary-text-color);    
    transition: color 0.3s;
    &:hover {
        cursor: pointer;
    }
}

.tab-header-selected {
    color: var(--primary-text-color);
}

.tab-header-container {
    display: flex;
    z-index: 20;
}