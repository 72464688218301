$base-font-family: 'Inter', sans-serif;

$base-font-weight-light: 300;
$base-font-weight: 400;
$base-font-weight-bold: 600;

//-- Links ------------------------------------------------------------
$base-link-color: $global-brand;
$base-link-color-hover: darken($global-brand, 10%);

//-- Box sizing -------------------------------------------------------
*,
*:before,
*:after {
    box-sizing: border-box;
}

img,
video {
    // max-width: 100%; // we have images in the editor that has been affected by this property. So we kept it off for now.
    height: auto;
    display: block;
}

//-- z-index ----------------------------------------------------------
@function z-index($key) {
    @if map-has-key($z-index, $key) {
        @return map-get($z-index, $key);
    }

    @warn "Unknown `#{$key}` in $z-index.";
    @return null;
}

$z-index: (
    dropdown: 10,
    modal: 100,
    notification: 1000,
    dialog: 10000,
    vendor: 100000
);

//-- Border radius ----------------------------------------------------
$base-border-radius: 4px;

$base-border-radius--small: 2px;

$base-int-icon-size: 16px;
$base-sup-icon-size: 22px;

%cursor--pointer,
.cursor--pointer {
    cursor: pointer;
}

.authorization-disabled,
.authorization-disabled * {
    cursor: not-allowed !important;
    opacity: 0.7 !important;
    pointer-events: none !important;
}
.authorization-disabled:hover,
.authorization-disabled:hover * {
    cursor: not-allowed !important;
    opacity: 0.7 !important;
    pointer-events: none !important;
}

.disabled-feature * {
    opacity: 0.1 !important;
    cursor: default !important;
}

.Toastify__toast-body {
    white-space: pre-line;
    background-color: var(--primary-color);
    color: $global-white;
    padding: 15px 15px 15px 15px;
    border-radius: 3px !important;
}
.Toastify__toast {
    border-radius: 3px !important;
    /* -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards; */
}
.Toastify__toast--info {
    background: lighten($global-brand, 15%) !important;
}
.Toastify__toast--info-bubble {
    min-height: 10px !important;
    width: 380px;
}
.Toastify__toast--info-bubble-body {
    background-color: transparent;
    padding: 2px 5px 2px 5px;
    font-size: 10px;
}

/*toggle sensitive info*/
.sensitive_data,
div:focus .sensitive_toggle {
    display: none;
}
div:focus .sensitive_data {
    display: block;
}
.sensitive_toggle.rounded {
    cursor: pointer;
    padding: 5px;
    background-color: silver;
}

/*check box*/

.check-container {
    display: block;
    position: relative;
    padding-left: calc(18px + 0.5rem);
    cursor: pointer;
    user-select: none;
}

.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.check-container__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    border-radius: 3px;
    line-height: 17px;
    width: 17px;
    background-color: var(--control-border-color);
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.check-container .text--small {
    line-height: 17px;
}

.check-container__checkmark i {
    position: relative;
    display: block;
    font-size: 10px;
    line-height: 18px;
    height: 100%;
    padding: 0;
    top: 0;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.15s ease;
    transform-origin: center;
    color: var(--primary-text-color);
}

.check-container:hover input ~ .check-container__checkmark i {
    opacity: 1;
    transform: scale(1);
}

.check-container input:checked ~ .check-container__checkmark {
    background-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
}

.check-container input:checked ~ .check-container__checkmark i {
    opacity: 1;
    transform: scale(1);
    color: #fff;
}

.mrnda-fullscreen-modal > {
    .modal-dialog {
        max-width: inherit;
    }
}

.mrnda-fullscreen-modal .modal-content {
    height: 90vh;
    margin: 20px;
}



iframe::-webkit-scrollbar {
    width: 12px; /* Adjust the width as needed */
  }
  
  /* Style the scrollbar thumb */
  iframe::-webkit-scrollbar-thumb {
    background-color: red; /* Color of the thumb */
    border-radius: 6px; /* Round the corners of the thumb */
  }
  
  /* Style the scrollbar track in Firefox */
  iframe::-webkit-scrollbar-track {
    background-color: red; /* Color of the track */
  }

  @media (min-width: 767px) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #818181a6;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    ::-webkit-scrollbar-corner { background: transparent }
  }


.fullscreen-modal
{
    padding: 0px;
    margin: 0px;
    & .modal-content {        
        height: 100vh;
        width: 100vw;
    }
    & .modal-header {
        padding: 0;
        margin: 0;
        border-radius: 0;
    }
}