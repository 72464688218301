$thumb-corner-radius: 4px;
.mrnda-video-editor-area-timeline {
    width: 100%;
    .rc-slider-handle {
        background: url(https://www.pdftron.com/samples/web/samples/advanced/video/icons.png)
            no-repeat -514px -226px;
        background-size: 650px;
        border-radius: unset;
        border: unset;
        margin-top: -23px;
        height: 25px;
    }
    .rc-slider-rail {
        background-color: transparent;
    }
    .rc-slider {
        top: 22px;
    }
    .rc-slider-track {
        background-color: #2196f3;
    }

    .horizontal-slider {
        width: 100%;
        height: 100%;            
    }
    .horizontal-slider .example-track {
        height: 100%;
        
    }
    .example-track {
        position: relative;
        
    }

    .example-track.example-track-2 {        
    }
    .example-track.example-track-1 {        
        border: 2px var(--icon-text-color) solid;        
    }
    

    .example-track-1-selected-area {
        background-color: var(--icon-text-color);
        height: 100%;
        opacity: .3;
    }
    
    .horizontal-slider .example-thumb {       
        width: 5px;
        height: 100%;
        /* line-height: 48px; */        
        /* left: 2px; */
        // margin-left: -1px;
    }
    .example-thumb-0 {
        margin-left: -12px;
        border-radius: $thumb-corner-radius 0 0 $thumb-corner-radius ;
    }
    .example-thumb-1 {
        margin-left: 12px;
        border-radius: 0 $thumb-corner-radius $thumb-corner-radius 0;

    }
    .example-thumb {
        font-size: 0.9em;
        text-align: center;
        background-color: var(--icon-text-color);
        color: white;
        cursor: pointer;
        width: 12px !important;
        color: black;
        display: flex;        
        align-items: center;
        justify-content: center;
        
    }
}
.mrnda-video-editor-area-controls-rangle-selector{    
    height: 80px;
    width: 100%;
    z-index: 7;  
    position: absolute;
     
}