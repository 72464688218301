.reply {
    &__header {
        display: flex;

        flex-direction: row;
        justify-content: flex-start;
        height: auto;
        position: relative;

        .reply__avatar {
            flex: 0 28px;
            margin-top: 2px;
            .avatar {
                position: relative;
                border-radius: 50%;
                font-size: 10px;
                line-height: 30px;
                text-align: center;
                text-transform: uppercase;
                background: var(--primary-color);
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-style: normal;
            }
            .avatar--small {
                width: 24px;
                height: 24px;
            }
            .avatar--initials {
                padding-bottom: 2px;
            }
        }
        .reply__details {
            flex: 1;
            flex-wrap: wrap;
            min-width: 0;
            line-height: 15px;
            .reply__author {
                overflow: hidden;
                font-size: 13px;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: var(--primary-text-color);
                height: 20px;
                margin-bottom: -1px;
            }
            .reply__date {
                flex: 1;
                display: inline-block;
                font-size: 11px;
                color: var(--secondary-text-color);
                margin-right: 8px;
                .date-field {
                    position: relative;
                    font-weight: 400;
                    font-style: normal;
                }
            }
            .reply__reply-count {
                display: inline-block;
                color: #666;
                font-size: 12px;
            }
        }
        .reply__options {
            margin: 0 0 0 10px;
            width: 24px;
            position: relative;
            right: -12px;
            top: -12px;
        }
        .reply__header-close {
            -webkit-box-flex: 0;
            -moz-box-flex: 0;
            box-flex: 0;
            -webkit-flex: 0 24px;
            -moz-flex: 0 24px;
            -ms-flex: 0 24px;
            flex: 0 24px;
            display: none;
            padding: 0 0 0 8px;
            line-height: 30px;
            text-align: right;
        }
    }
    &__body-wrapper {
        position: relative;
        display: flex;
        margin: 8px 0px 12px 28px;
        .reply__number {
            position: absolute;
            right: calc(100% + 4px);
            flex: 0 0 auto;
            width: 40px;
            padding: 8px 0 0 0;
            font-size: 11px;
            text-align: right;
            line-height: 13px;
            color: #767676;
        }
        .reply__body {
            flex: 0 0 auto;
            position: relative;
            overflow: hidden;
            width: 100%;
            padding: 8px;
            border: 0;
            border-radius: 4px;
            color: var(--primary-text-color);
            background: var(--primary-background-color);
            .comment-text {
                white-space: pre-wrap;
                word-break: break-word;
            }
            .reply__text {
                display: inline;
                white-space: pre-wrap;
                font-size: 12px;
                color: var(--primary-text-color);
            }
            
        }
    }
    &__metadata {
        position: relative;
        overflow: hidden;
        padding: 10px 9px 16px 48px;
        line-height: 13px;
        color: #666;
        word-wrap: break-word;
        .time-range {
            align-items: center;
            justify-content: center;
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            font-style: normal;
            .time-range__item {
                flex: 1;
            }
        }
    }

    &__attachments-list{
        margin:  0px 0px 0px 20px;
    }
    &__attachments-collapsed{
        margin: 4px 0 0px 42px;
        padding: 0px 0px 0 32px;  
        color: var(--primary-text-color);
        opacity: 0.6;
        transition: all 0.3s;
        &:hover {
            opacity: 1;
        }          
    }

    &:hover{

       .reply-delete-button{
            opacity: 1;
        }
    }
}
.reply-delete-button{
    opacity: 0;
    i {       
        font-size: 14px;
    }
}

.reply__body-edit-footer {
    margin-top: 16px;
}