@import "plugins/all";
@import "base/all";
@import "layout/all";
@import "utility/all";
@import "components/all";
@import "blocks/all";
@import "../../assets/Styles/Dialogs.css";


.app {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
}

.route-container {    
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.main-content {
    flex: 1;    
    display: flex;
    overflow: hidden;
}