.mrnda-empty-state-wrapper {
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mrnda-empty-state {
  padding: 0px 12px 150px 12px;

}

.mrnda-empty-state__icon {
  margin-left: auto;
  margin-right: auto;
  height: $base-sup-icon-size*5;
  width: $base-sup-icon-size*5;
  opacity: .2;
}

.mrnda-fluid-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(#{$base-spacing-unit*23}, 1fr));
  grid-gap: $base-spacing-unit*2;
}

.mrnda-flex-center-wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}
