$mrnda-sidebar-width: $base-spacing-unit*40;

.mrnda-main-content {
  position: relative;
  background-color: var(--secondary-background-color);
  //height: calc(100vh - #{$mrnda-navigation-height});
  
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  // top: $mrnda-navigation-height;
  -webkit-overflow-scrolling: touch;
}

.mrnda-main-content--subnav-open {
  //height: calc(100vh - #{$mrnda-navigation-height*2});
  // top: $mrnda-navigation-height*2;
}

.mrnda-main-content--sidebar-right-open {
  position: relative;
  width: calc(100% - #{$mrnda-sidebar-width});
  left: 0;
}

.mrnda-main-content--sidebar-left-open {
  position: relative;
  width: calc(100% - #{$mrnda-sidebar-width});
}
