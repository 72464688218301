// -- Text alignment --------------------------------------------------
.text--left {
  text-align:left;
}

.text--center {
  text-align:center;
}

.text--right {
  text-align:right;
}

// -- Add/remove floats -----------------------------------------------
.float--right {
  float:right;
}

.float--left {
  float:left;
}
.float--none {
  float:none;
}

// -- Alignment -------------------------------------------------------
%block--center,
.block--center {
  display: block;
  margin: 0 auto;
}

// -- Image alignment -------------------------------------------------
.image--center {
  @extend %block--center;
}

.image--left {
  display: block;
  float: left;
}

.image--right {
  display: block;
  float: right;
}

// -- Clearfix --------------------------------------------------------
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
