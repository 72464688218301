//-- Adapter ----------------------------------------------------------
$base-background-color: $global-background;
$base-font-color:       $global-grey;
$base-font-color-dark:  $global-black;

//-- Config -----------------------------------------------------------
$header-font-family: $base-font-family;

$base-font-size:    14px;
$base-spacing-unit: 10px;

$base-line-height: $base-spacing-unit * 2;

//-- Scale ------------------------------------------------------------
$font-h1-size: $base-font-size * 1.5;

//--- Main ------------------------------------------------------------
html {
  font-size: $base-font-size;
}

html, body {
  background-color:        var(--primary-background-color);
  font-family:             $base-font-family;
  font-size:               $base-font-size;
  line-height:             $base-line-height;
  color:                   var(--secondary-text-color);
  font-kerning:            normal;
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

%header-base,
h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  color:       var(--primary-text-color);
  margin:      0;
  font-weight: $base-font-weight;
}

h1 {
  @extend %typography--h1;
}

h2 {
  @extend %typography--h2;
}

p {
  margin: 0;
}

a {
  color:           $base-link-color;
  text-decoration: none;
  outline:         none;
  transition: color .3s ease;

  &:hover {
    @extend %reset-outline;
    color:           $base-link-color-hover;
    text-decoration: none;
  }
}

.link-block {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

strong,
b {
  font-weight: $base-font-weight-bold;
}

//-- Helpers ----------------------------------------------------------
%typography--h1,
.typography--h1 {
  @extend %header-base;
  font-size: $font-h1-size;
  line-height: $base-line-height * 1.25;
}

%typography--h2,
.typography--h2 {
  @extend %header-base;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight-bold;
}

%typography--h3,
.typography--h3 {
  @extend %header-base;
  @extend %typography--small;
  @extend %typography--muted;
  color: var(--secondary-text-color);
  text-transform: uppercase;
}

.typography--readabilty {
  max-width: 33em;
}

.typography--caution {
  color: $global-caution;
}

%typography--dark,
.typography--dark {
  color: var(--primary-text-color);
}

.typography--light {
  color: var(--secondary-text-color);
}

%typography--small,
.typography--small {
  font-size: 0.857rem;
  letter-spacing: .01em;
}

%typography--muted,
.typography--muted {
  opacity: .65;
}

.typography--ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typography--break {
  word-break: break-all;
}

.typography--left {
  text-align: left;
}

.typography--center {
  text-align: center;
}

.typography--right {
  text-align: right;
}
