// .video-js .vjs-progress-control { display: none; }
// .video-js .vjs-control-bar { justify-content: flex-end;}

// $mrnda-video-editor-body-color: #292b2f;
// $mrnda-video-editor-element-color: #3f4346;
// $mrnda-video-editor-font-color: white;

$mrnda-video-editor-body-color: #f7f8f9;
$mrnda-video-editor-element-color: white;
$mrnda-video-editor-font-color: black;

@mixin neumorphic-card {
    // border-radius: 10px;
    background: $mrnda-video-editor-element-color;
    // box-shadow: -20px 12px 12px 2px #1d1f22, 3px -5px 20px #292b30;
}

.mrnda-video-compare {
    // padding: 20px 20px 20px 20px;
    height: calc(100% - 20px);
    background-color: var(--primary-background-color);
    &__container {
        top: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        // width: 1430px;
        // margin: 0px 20px 0px 0px;
        height: calc(100% - 0px);
        overflow: hidden;
    }
    &__toolbar {
        display: flex;
        padding: 15px;
        justify-content: space-around;
        border-bottom: 1px solid var(--border-color);
        background: var(--secondary-background-color);
        &__settings {
            display: flex;
            justify-self: center;
            margin: 0 8px;
            &--item {
                color: var(--secondary-text-color);
                font-size: 16px;
                margin: 0 15px 0 0px;
                transition: all 0.3s;
                &:hover {
                    color: var(--primary-color);
                    cursor: pointer;
                }
                &.active {
                    color: var(--primary-color);
                }
                .color-palette{
                    display: flex;
                    &__color-outer{
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        border: 1px solid transparent;
                        cursor: pointer;
                        &.selected{
                            border: 1px solid var(--primary-color-shade-2);
                        }
                    }
                    &__color-inner{
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        border: 1px solid #1d1e22;
                    }
                }
            }
            &__auto-compare {
                position: absolute;
                top: 55px;
                background:$mrnda-video-editor-element-color;
                padding: 12px;
                z-index: 998;
                margin-left: -40px;
                border-radius: 4px;

                &__opacity {
                    &--range{
                        width: 100%;
                    }
                }
                &__colors {
                    .color-palette{
                        display: flex;
                        &__color-outer{
                            width: 22px;
                            height: 22px;
                            border-radius: 3px;
                            border: 1px solid transparent;
                            margin: 2px;
                            cursor: pointer;
                            &.selected{
                                border: 1px solid var(--primary-color-shade-2);
                            }
                        }
                        &__color-inner{
                            width: 20px;
                            height: 20px;
                            border-radius: 3px;
                            border: 1px solid #1d1e22;
                        }
                    }
                }
            }
        }
        &__header {
            margin-right: 8px;
            justify-content: center;
            display: flex;
            flex: 1;
            &--text {
                font-size: 16px;
                margin: 0 4px 0 0;
            }
            &--version {
                font-size: 12px;
                margin: 2px 0 0 0;
            }
        }
        &__close {
            position: absolute;
            right: 20px;
        }
    }
    &__player-area {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;        
    }
}
