//-- Primary Color ----------------------------------------------------
$global-calm: #0091FF;

//-- Secondary Color --------------------------------------------------
$global-success: #12C97C;
$global-caution: #FF5F39;

//-- Neutral Colors ---------------------------------------------------
$global-white: #ffffff;
$global-tint:  #f7f8f9;
$global-grey:  #66666a;
$global-black: #11111a;

//-- Brand Color ------------------------------------------------------
$global-brand:          $global-calm;
$global-brand-contrast: $global-white;

//-- Default Colors for Elements --------------------------------------
$global-background:  $global-white;
$base-divider-color: rgba($global-black,.1);

//-- Global Dark Mode Colors
$global-darkmode-white: #e4e6eb;
$global-darkmode-white-secondary: #b0b3b8
