//-- Helpers ----------------------------------------------------------
@function margin-get-size($key) {
  $sizes: map-get($margin-config, sizes);
  @return map-get($sizes, $key);
}

//-- Config -----------------------------------------------------------
$margin-spacing:  $base-spacing-unit;
$margin-key-0x:  "-0x";
$margin-key-_5x:  "-_5x";
$margin-key-1x:   "-1x";
$margin-key-1_5x: "-1_5x";
$margin-key-2x:   "-2x";
$margin-key-3x:   "-3x";
$margin-key-4x:   "-4x";

$margin-config: (
  modifiers: top bottom left right,
  variations: $margin-key-0x $margin-key-_5x $margin-key-1x $margin-key-1_5x $margin-key-2x $margin-key-3x  $margin-key-4x,
  sizes: (
    $margin-key-0x:  $margin-spacing*0,
    $margin-key-_5x:  $margin-spacing*0.5,
    $margin-key-1x:   $margin-spacing*1,
    $margin-key-1_5x: $margin-spacing*1.5,
    $margin-key-2x:   $margin-spacing*2,
    $margin-key-3x:   $margin-spacing*3,
    $margin-key-4x:   $margin-spacing*4
  )
);

//-- Main -------------------------------------------------------------
@each $variation in map-get($margin-config, variations) {
  $size: margin-get-size($variation);
  $modifiers: map-get($margin-config, modifiers);

  %margin#{$variation},
  .margin#{$variation} {
    margin: $size;
  }

  %margin#{$variation}--sides,
  .margin#{$variation}--sides {
    margin-left:  $size;
    margin-right: $size;
  }

  %margin#{$variation}--ends,
  .margin#{$variation}--ends {
    margin-top:    $size;
    margin-bottom: $size;
  }

  @each $modifier in $modifiers {
    %margin#{$variation}--#{$modifier},
    .margin#{$variation}--#{$modifier} {
      margin-#{$modifier}: $size;
    }
  }
}
