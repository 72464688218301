.mrnda-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  @include clearfix;
  padding: $base-spacing-unit*2 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    border-radius: 1px;
    background: $base-divider-color;
    width: 100%;
    bottom: 0;
  }
}

.mrnda-tabs--bg {
  background: $global-white;
}

.mrnda-tabs__item {
  @extend %typography--small;
  display: inline;
  position: relative;
  margin-left: $base-spacing-unit*2;
  padding: $base-spacing-unit*2 0;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &.active::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    background: var(--primary-color);
    width: 100%;
    bottom: -3px;
    left: 0;
  }
}

.mrnda-tabs__item--active {
  @extend %typography--dark;
  font-weight: 500;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    background: var(--primary-color);
    width: 100%;
    bottom: -3px;
    left: 0;
  }
}

//NOTE : This styles are only for video editor dark theme
//       This should be removed when global dar theme is intruduced
//============================start============================

.mrnda-tabs-themed__item {
  @extend %typography--small;
  display: inline;
  position: relative;
  margin-left: $base-spacing-unit*2;
  padding: $base-spacing-unit*2 0;
  text-transform: uppercase;
  color: var(--secondary-text-color);
  &:last-child {
    margin-right: 0;
  }

  &.active::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    background: var(--primary-color);
    width: 100%;
    bottom: -3px;
    left: 0;
  }
}
.mrnda-tabs-themed__item--active {
  color: var(--primary-text-color);
  font-weight: 500;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    background: var(--primary-color);
    width: 100%;
    bottom: -3px;
    left: 0;
  }
  &__tools{
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;

  }
}
//============================end==============================

/* Style the tab content */
.mrnda-tabs-content {
  display: none;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
}

/* Fade in tabs */
@-webkit-keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}
