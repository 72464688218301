// .video-js .vjs-progress-control { display: none; }
// .video-js .vjs-control-bar { justify-content: flex-end;}

// $mrnda-video-editor-body-color: #292b2f;
// $mrnda-video-editor-element-color: #3f4346;
// $mrnda-video-editor-font-color: white;

$mrnda-video-editor-body-color: #f7f8f9;
$mrnda-video-editor-element-color: white;
$mrnda-video-editor-font-color: black;

@mixin neumorphic-card {
    // border-radius: 10px;
    background: var(--secondary-background-color);
    // box-shadow: -20px 12px 12px 2px #1d1f22, 3px -5px 20px #292b30;
}

.mrnda-video-editor-body {
    // padding: 20px 20px 20px 20px;
    height: calc(100% - 20px);
    background-color: var(--primary-background-color);
    position: relative;
}

.mrnda-video-editor-container {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    // width: 1430px;
    // margin: 0px 20px 0px 0px;
    height: calc(100% - 0px);
}

.mrnda-editor-sidebar-new-comment-banner{   
    background-color: var(--primary-background-color);;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-radius: 6px;
    -webkit-box-shadow: 1px 1px 20px -8px rgba(0,0,0,0.26); 
    box-shadow: 1px 1px 20px -8px rgba(0,0,0,0.26);
    cursor: pointer;
    &__text {
        color: var(--primary-color);;
        text-align: center;
        font-size: 14px;
    }
}
    
.mrnda-video-editor-sidebar {
    padding: 14px 10px 4px 10px;    
    overflow: hidden hidden;    
    margin: 0 0 0 1px;
    flex: 1 0 345px;
    @include neumorphic-card;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(----border-color);
    &__header {
        flex-flow: row wrap;
        width: auto;
        &__container {
            display: flex;
            justify-content: space-between;
        }
        &__text {
            color: var(--primary-text-color);
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
            word-break: break-word;
        }
    }
    &__comments {
        overflow: hidden auto;
    }
    &__tabs {
        padding: 12px 0;
        &__item {
            margin-left: 0;
            margin-right: 20px;
            padding: 12px 0;
            cursor: pointer;
        }
    }
}


.mrnda-video-editor-area {
    flex: 1;
    margin: 0px 0px 0px 0px;
    min-width: calc(100% - 350px);
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-canvas-wrapper {
        transition: transform 0.3s ease;
    }
}

.mrnda-video-editor-area-canvas-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 25px 0;
    width: fit-content;
    margin: 0 auto 10px 0;
    align-items: center;
    overflow: auto;
}

.mrnda-video-editor-area-timeline-annotation-markers{
    height: 100%;
    width: 100%;    
}

.mrnda-video-editor-area-controls {
    display: flex;
    flex-direction: column;
    @include neumorphic-card;
    padding: 16px 16px 8px 16px;
    // border-top: 1px solid var(--border-color);
}

.mrnda-video-editor-area-timeline {
    position: relative;
    width: 100%;
    height: 16px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    transition: transform 0.3s, left 0.3s;
}

.mrnda-video-viewer-area-timeline {
    position: relative;
    width: 100%;
    height: 16px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
}

.mrnda-video-editor-area-controls-markers {                
    height: 80px;
    position: absolute;  
    width: 100%;
        
}
.mrnda-video-editor-area-controls-markers--item {
    cursor: pointer;
    position: absolute;
    width: 22px;
    height: 22px;    
    user-select: none;
    -webkit-transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    -o-transition: left 0.5s ease;
    transition: left 0.5s ease;
}

.mrnda-video-editor-area-controls-markers--line {
    
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    top: 44%;
    position: absolute;

    -webkit-transition: left 0.5s ease,  background-color 0.5s;
    -moz-transition: left 0.5s ease,  background-color 0.5s;
    -o-transition: left 0.5s ease,  background-color 0.5s;
    transition: left 0.5s ease,  background-color 0.5s;
}

.mrnda-video-editor-area-controls-markers--profile {
    
    
    
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    outline: 2px solid var(--primary-background-color);
    background-color: var(--primary-color);
   
    -webkit-transition:  left 0.5s ease,  background-color  0.5s;
    -moz-transition: left 0.5s ease,  background-color  0.5s;
    -o-transition: left 0.5s ease,  background-color  0.5s;
    transition: left 0.5s ease,  background-color  0.5s;
    font-size: 10px;
    text-align: center;
    z-index: 1;
}
.mrnda-video-editor-area-controls-seekbar {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    // margin: 20px 0 0 0px;
   
    background-color: var(--primary-background-color);   
    border-radius: 2px;
    position: absolute;
}



.mrnda-video-editor-area-controls-seekbar-progress{
    height: 100%;
    background: var(--control-border-color);          
    position: absolute;
    transition: width 0.5s ease;   
    display: flex;
    justify-content: end;  
    cursor: pointer;  
    opacity: 0.5;
    border-radius: 2px;
    
}



.mrnda-video-editor-area-controls-seekbar-frame-marker {
    height: 116%;
    width: 2px;    
    position: absolute;
    z-index: 1;
    margin-top: -5px;
    transition: left 0.3s;
}

.mrnda-video-editor-area-controls-rangle-selector--tools {
    position: absolute;
    margin-top: -36px;
    height: 26px;
    display: flex;
    transition: left 0.3s;
}


.mrnda-video-editor-area-controls-rangle-selector--tools-close{
    color:var(--primary-text-color);
    height: 26px;
    width: 26px;
    background-color: #595959;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    &:hover {
        cursor: pointer;
    }

}

.mrnda-video-editor-area-controls-rangle-selector--tools-tick {
    color:var(--primary-text-color);
    height: 26px;
    width: 26px;
    background-color: var(--primary-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    &:hover {
        cursor: pointer;
    }
}
.mrnda-video-editor-area-controls-rangle-selector--tools-range {
    color:var(--primary-text-color);
    background-color: var(--icon-text-color);
    padding: 2px 12px;
    border-radius: 4px;
}

.mrnda-video-editor-area-controls-seekbar::-webkit-progress-bar {
    background-color: var(--primary-background-color);   
    border-radius: 2px;
}
.mrnda-video-editor-area-controls-seekbar::-webkit-progress-value {
    border-radius: 2px;
    background-color: var(--primary-color); 
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;       
}
.mrnda-video-editor-area-controls-seekbar::-moz-progress-bar {
    border-radius: 2px;
    background: var(--control-border-color);
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}

.mrnda-video-viewer-area-controls-seekbar {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    // margin: 20px 0 0 0px;
   
    background-color: var(--primary-background-color);   
    border-radius: 2px;
    position: absolute;
}


.mrnda-video-viewer-area-controls-seekbar-progress{
    height: 100%;
    background: var(--control-border-color);          
    position: absolute;
    transition: width 0.5s ease;   
    display: flex;
    justify-content: end;  
    cursor: pointer;  
    opacity: 0.5;
    border-radius: 2px;
    
}

.mrnda-video-viewer-area-controls-seekbar::-webkit-progress-bar {
    background-color: var(--primary-background-color);   
    border-radius: 2px;
}
.mrnda-video-viewer-area-controls-seekbar::-webkit-progress-value {
    border-radius: 2px;
    background-color: var(--control-border-color);
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;       
}
.mrnda-video-viewer-area-controls-seekbar::-moz-progress-bar {
    border-radius: 2px;
    background: var(--control-border-color);
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}


.mrnda-video-viewer-area-controls-seekbar-light {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    // margin: 20px 0 0 0px;
   
    background-color: var(--tertiary-background-color);   
    border-radius: 2px;
    position: absolute;
}


.mrnda-video-viewer-area-controls-seekbar-light-progress{
    height: 100%;
    background: var(--control-border-color);          
    position: absolute;
    transition: width 0.5s ease;   
    display: flex;
    justify-content: end;  
    cursor: pointer;  
    opacity: 0.5;
    border-radius: 2px;
    
}

.mrnda-video-viewer-area-controls-seekbar-light::-webkit-progress-bar {
    background-color: var(--tertiary-background-color);   
    border-radius: 2px;
}
.mrnda-video-viewer-area-controls-seekbar-light::-webkit-progress-value {
    border-radius: 2px;
    background-color: var(--control-border-color);
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;       
}
.mrnda-video-viewer-area-controls-seekbar-light::-moz-progress-bar {
    border-radius: 2px;
    background: var(--control-border-color);
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}


//NOTE :Non themed seekbar should be removed when full dark mode is supported
//=============================Start======================================
.mrnda-video-editor-area-controls-seekbar-fixed-theme {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    width: 100%;
    height: 15px;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    margin: 20px 0 0 0px;
}
.mrnda-video-editor-area-controls-seekbar-fixed-theme::-webkit-progress-bar {
    background-color: $mrnda-video-editor-element-color;
    // box-shadow: inset 0px 0px 10px 1px rgba(117, 182, 255, 0.4),
    //     0px 0px 20px rgba(117, 182, 255, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    border-radius: 2px;
}
.mrnda-video-editor-area-controls-seekbar-fixed-theme::-webkit-progress-value {
    border-radius: 2px;
    background: rgb(0, 255, 67);
    box-shadow: inset 0px 0px 10px 2px rgba(117, 255, 121, 0.5),
        0px 0px 20px rgba(117, 214, 133, 0.5);

    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}
.mrnda-video-editor-area-controls-seekbar-fixed-theme::-moz-progress-bar {
    border-radius: 2px;
    background: rgb(0, 255, 67);
    box-shadow: inset 0px 0px 10px 2px rgba(117, 255, 121, 0.5),
        0px 0px 20px rgba(117, 214, 133, 0.5);

    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}
//=============================End==================================



.mrnda-video-editor-area-controls-buttons-wrapper {
    display: flex;
    flex-direction: row;
    margin: 2px 0 5px 0;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
    border-left: none;
    border-right: none;
}

.mrnda-video-editor-area-controls-buttons-wrapper--mobile {
    border: none;
}

/* unholy css to style input type="range" */
.mrnda-video-editor-area-controls-buttons--play-pause {
    padding: 6px;
    cursor: pointer;
    margin-left: 2px;
    color: var(--icon-text-color);
    > i {
        font-size: 25px;
    }
}
.mrnda-video-editor-area-controls-buttons--step {
    color: var(--icon-text-color);
    font-size: 20px;
    cursor: pointer;
}

.mrnda-video-editor-area-controls-sides {
    flex: 0 0 33%;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    & span {
        cursor: pointer;
    }
    &--volume-icon {
        color: var(--icon-text-color);
        font-size: 20px;
    }
    &--volume {
        -webkit-appearance: none;
        background: transparent;
        width: 100px;
        margin: 0 10px 0 10px;

        &::focus {
            outline: none;
        }
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0 0 1px rgba(13, 13, 13, 0);
            background: var(--icon-text-color);
            opacity: 0.7;
            border-radius: 5px;
            border: 0.2px solid rgba(1, 1, 1, 0);
        }
        &::-webkit-slider-thumb {
            height: 15px;
            width: 15px;
            border-radius: 50px;
            background: #0091ff;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -3.5px;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }
        &::-moz-range-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0 0 1px rgba(13, 13, 13, 0);
            background: darken($mrnda-video-editor-body-color, 30%);
            border-radius: 1.3px;
            border: 0.2px solid rgba(1, 1, 1, 0);
        }
        &::-moz-range-thumb {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
            height: 15px;
            width: 15px;
            border-radius: 50px;
            background: #0091ff;
            cursor: pointer;
        }
    }

    &--settings {
        color: white;
    }
}

//NOTE : This should be deleted when full dark mode is supported
//===============================start================================
.mrnda-video-editor-area-controls-sides-fixed-theme {
    flex: 0 0 33%;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    & span {
        cursor: pointer;
    }
    &--volume-icon {
        color: $mrnda-video-editor-font-color;
        font-size: 20px;
    }
    &--volume {
        -webkit-appearance: none;
        background: transparent;
        width: 100px;
        margin: 0 10px 0 10px;

        &::focus {
            outline: none;
        }
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0 0 1px rgba(13, 13, 13, 0);
            background: darken($mrnda-video-editor-body-color, 30%);
            border-radius: 5px;
            border: 0.2px solid rgba(1, 1, 1, 0);
        }
        &::-webkit-slider-thumb {
            height: 15px;
            width: 15px;
            border-radius: 50px;
            background: #0091ff;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -3.5px;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }
        &::-moz-range-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0 0 1px rgba(13, 13, 13, 0);
            background: darken($mrnda-video-editor-body-color, 30%);
            border-radius: 1.3px;
            border: 0.2px solid rgba(1, 1, 1, 0);
        }
        &::-moz-range-thumb {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
            height: 15px;
            width: 15px;
            border-radius: 50px;
            background: #0091ff;
            cursor: pointer;
        }
    }

    &--settings {
        color: white;
    }
}
//===============================end==============================
.mrnda-video-editor-area-controls-buttons {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.mrnda-video-editor-area-controls-sides {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

.mrnda-video-editor-area-controls-seekbar-tooltip {
    --left-panel-width: 300px;
    --primary-color: #2c2b3a;
    --secondary-color: #4d4c5f;
    --border-color: #555555;
    --button-hover-color: #686880;
    --button-active-color: #686880;
    --text-color: #ffffff;
    --icon-color: #ffffff;
    --icon-active-color: #ffffff;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    cursor: default;
    display: block;
    position: absolute;
    z-index: 1;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 10pt;
    transform: translate(-50%, 6px);
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    margin-top: 20px;
}

.mrnda-video-editor-area-controls-seekbar-tooltip::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.mrnda-video-editor-area-toolbox {
    // border: black 1px solid;
    display: flex;
    padding: 12px 22px;    
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    @include neumorphic-card;
    &-contributors{
        display: flex;
        justify-content: start; 
        flex: 1;   
    }    
    &-shapes {
        display: flex;
        justify-content: center; 
        flex: 1;   
        &--item {
            color: var(--primary-text-color);
            font-size: 22px;
            margin: 0 17px 0 0px;
            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
            &.active {
                color: var(--primary-color);
            }
        }
    }
    &-tools {        
        display: flex;
        justify-content: flex-end;
        flex: 1;           
        &--item {
            color: var(--primary-text-color);
            font-size: 22px;
            margin: 0 15px 0 0px;
            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
            &.active {
                color: var(--primary-color);
            }
        }
    }
}

.mrnda-video-editor-area-controls-button--timers {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    color: var(--primary-text-color);
}
.canvas-container {
    // border: black 1px solid;
}
.canvas-container {
    position: absolute !important;
}

.fa-caret-up-down {
    &::before {
        content: '\f0d8';
        position: relative;
        left: 4px;
        top: -4px;
        right: 5px;
    }
    &::after {
        content: '\f0d7';
        position: relative;
        top: 3px;
        right: 5px;
    }
}


.drop-down-item-no-transform{
    text-transform: none;
    color: var(--primary-text-color);
    &:hover{
        color: var(--primary-text-color);
    }
}
