.create-proof-tabs {
    padding: 24px 0px;
    // background: aliceblue;
    background: var(--secondary-background-color);
}

.create-proof-tab-selector {
    font-size: 16px;
    padding: 24px 10px;
    cursor: pointer;
}
.create-proof-tab-selector:first-child {
   margin-left: 32px;
}

.create-proof-input-group {
    // min-height: 14vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    & input {
        background-color: var(--primary-background-color); 
        border: 1px solid var(--control-border-color);
        color: var(--primary-text-color);
        &:focus{
            color: var(--primary-text-color);
            background-color: var(--primary-background-color);
        } 
        &:disabled {
            background-color: var(--secondary-background-color);
            opacity: 0.8;
            box-shadow: none;
        }      
    }
}

.form-group > .create-proof-input-label {
    font-weight: 500;
}
