%mrnda-avatar,
.mrnda-avatar {
    height: $base-spacing-unit * 4;
    width: $base-spacing-unit * 4;
    line-height: $base-spacing-unit * 4;
    text-align: center;
    border-radius: 50%;
    background: rgba($global-brand-contrast, 0.4);
    color: $global-white;
    font-weight: $base-font-weight-bold;
    user-select: none;
    // cursor: auto;
}

%mrnda-avatar--secondary,
.mrnda-avatar--secondary {
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    background: lighten($global-brand, 10%);
    color: $global-white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-style: normal;
    padding-bottom: 2px;
}

.mrnda-avatar--secondary--small {
    @extend %mrnda-avatar--secondary;
    font-size: 10px;
    width: 24px;
    height: 24px;
}

.mrnda-avatar--secondary--x-small {
  @extend %mrnda-avatar--secondary;
  font-size: 8px;
  width: 18px;
  height: 18px;
}