.mrnda-folder-list {
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;

  & .mrnda-folder-list {
    @extend %padding-2x--left;
  }
}

%mrnda-folder,
.mrnda-folder {
  display: flex;
  align-items: center;
  position: relative;
}

%mrnda-folder__arrow,
.mrnda-folder__arrow {
  @extend %margin-_5x--right;
  cursor: pointer;
  display: block;
  width: $base-spacing-unit;
  height: $base-spacing-unit;
  position: relative;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background: red;

  .mrnda-folder--parent & {
    background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-right.svg');
  }

  .mrnda-folder--parent-open & {
    background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-down.svg');
  }
}

%mrnda-folder__info-wrapper,
.mrnda-folder__info-wrapper {
  @extend %padding-_5x;
  @extend %padding-1x--right;
  display: flex;
  align-items: center;
  border-radius: $base-border-radius;
  cursor: pointer;
  transition: background-color .3s ease, box-shadow .3s ease;

  &:hover {
    box-shadow: 0 0 0 1px var(--primary-color) inset;
  }

  .mrnda-folder--open & {
    background-color: rgba($global-brand,.15);
  }
}

%mrnda-folder__icon,
.mrnda-folder__icon {
  @extend %margin-_5x--right;
  display: block;
  width: $base-sup-icon-size;
  height: $base-sup-icon-size;
  position: relative;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-image: url('../../assets/Images/Icons/supportive/icon-sup-folder.svg');

  .mrnda-folder--open &,
  .mrnda-folder--parent-open & {
    background-image: url('../../assets/Images/Icons/supportive/icon-sup-folder-open.svg');
  }
}

.mrnda-folder__title {
  color: $global-black;
  white-space: nowrap;
}






.rc-tree {
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
}

.rc-tree-child-tree {
  list-style: none;
  margin: 0;
  @extend %padding-2x--left;
}

.rc-tree-switcher {
  @extend %margin-_5x--right;
  cursor: pointer;
  display: block;
  width: $base-spacing-unit;
  height: $base-spacing-unit;
  position: relative;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  float: left;
  top: calc(16px - #{$base-spacing-unit*.5});

  &.rc-tree-switcher_close {
    background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-right.svg');
    
  }

  &.rc-tree-switcher_open {
    background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-down.svg');
    
  }
}

.rc-tree-node-content-wrapper {
  @extend %padding-_5x;
  @extend %padding-1x--right;
  display: inline-flex;
  align-items: center;
  border-radius: $base-border-radius;
  cursor: pointer;
  transition: background-color .3s ease, box-shadow .3s ease;

  &:hover {
    box-shadow: 0 0 0 1px var(--primary-color) inset;
  }

  &.rc-tree-node-selected {
    background-color: rgba($global-brand,.15);
  }
}

.rc-tree-node-content__icon {
  @extend %margin-_5x--right;
  display: block;
  width: $base-sup-icon-size;
  height: $base-sup-icon-size;
  position: relative;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-image: url('../../assets/Images/Icons/supportive/icon-sup-folder.svg');

  .rc-tree-node-content-wrapper-open {
    background-image: url('../../assets/Images/Icons/supportive/icon-sup-folder-open.svg');
  }

  .rc-tree-node-selected & {
    background-image: url('../../assets/Images/Icons/supportive/icon-sup-folder-open.svg');
  }
}

.rc-tree-title {
  color: var(--primary-text-color);
  white-space: nowrap;
}
