.section-enter {
    opacity: 0;   
}

.section-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 250ms;
}

.section-exit {
    opacity: 1;
}

.section-exit-active {
    opacity: 0;
    transition: opacity 250ms;
}



.one {    
    -webkit-animation: dot 1.1s infinite;
    -webkit-animation-delay: 0.0s;
    animation: dot 1.1s infinite;
    animation-delay: 0.0s;
}

.two {    
    -webkit-animation: dot 1.1s infinite;
    -webkit-animation-delay: 0.1s;
    animation: dot 1.1s infinite;
    animation-delay: 0.1s;
}

.three {    
    -webkit-animation: dot 1.1s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.1s infinite;
    animation-delay: 0.2s;
}

@-webkit-keyframes dot {
    0% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes dot {
    0% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.skeleton-fetcher-host{
    position: relative
}

.skeleton-skeleton-fetcher-mask{
    width: 100%;
    height: 100%;
    position: absolute;    
    z-index: 10;
    border-radius: 4px;
}