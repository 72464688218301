@mixin placerholder-text {
    color: rgba($base-font-color, 0.5);
    opacity: 1;
}

::-webkit-input-placeholder {
    @include placerholder-text;
}
::-moz-placeholder {
    @include placerholder-text;
}
:-ms-input-placeholder {
    @include placerholder-text;
}

%mrnda-input-text,
.mrnda-input-text {
    @extend %reset-form-appearance;

    font-size: $base-font-size;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    border: none;
    background-color: var(--primary-background-color);
    border-radius: $base-border-radius;
    box-shadow: 0 0 0 1px var(--control-border-color), 0 0 0 1px var(--control-border-color) inset;
    height: $base-spacing-unit * 4;
    padding: 0 $base-spacing-unit * 1.5;
    line-height: $base-line-height;
    width: 100%;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease;
    color: var(--secondary-text-color);

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary-color), 0 0 0 1px var(--primary-color) inset;
        background-color: var(--primary-background-color);
        color: var(--secondary-text-color);
    }
    &:disabled {
        background-color: var(--secondary-background-color);
        opacity: 0.8;
        box-shadow: none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--secondary-text-color);
      opacity: 0.5; /* Firefox */
    }
    
    &::-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--secondary-text-color);
      opacity: 0.5; /* Firefox */
      
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--secondary-text-color);
      opacity: 0.5; /* Firefox */
    }
}


%mrnda-input-textarea,
.mrnda-input-textarea {
    @extend %reset-form-appearance;

    font-size: $base-font-size;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    border: none;
    background-color: var(--primary-background-color);
    border-radius: $base-border-radius;
    box-shadow: 0 0 0 1px var(--control-border-color), 0 0 0 1px var(--control-border-color) inset;    
    width: 100%;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease;
    color: var(--secondary-text-color);

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary-color), 0 0 0 1px var(--primary-color) inset;
        background-color: var(--primary-background-color);
        color: var(--secondary-text-color);
    }
    &:disabled {
        background-color: var(--secondary-background-color);
        opacity: 0.8;
        box-shadow: none;
    }
}

.mrnda-input-text--icon {
    position: relative;

    & .mrnda-input-text {
        padding-right: $base-spacing-unit * 4;
    }

    & .mrnda-icon-btn {
        position: absolute;
        border-radius: $base-border-radius--small;
        right: $base-spacing-unit * 0.5;
        top: $base-spacing-unit * 0.5;
    }
}

.mrnda-input-text--search {
    position: relative;

    & .mrnda-input-text {
        padding-left: $base-spacing-unit * 4;
    }

    & .mrnda-icon-btn {
        position: absolute;
        border-radius: $base-border-radius--small;
        left: $base-spacing-unit * 0.5;
        top: $base-spacing-unit * 0.5;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--secondary-text-color);
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--secondary-text-color);
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--secondary-text-color);
      }
}

.mrnda-input-label {
    font-weight: $base-font-weight-bold;
    display: block;
}

%mrnda-input-select,
.mrnda-input-select {    
    @extend %mrnda-input-text;
    position: relative;
    padding-right: $base-spacing-unit * 4;
    box-shadow: 0 0 0 1px var(--control-border-color), 0 0 0 1px var(--control-border-color) inset;
    background-repeat: no-repeat;
    background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-down.svg');
    background-size: 10px 10px;
    background-position: right 15px bottom 14px;
}

.custom-control-label.toggle::before {
    background: var(--primary-color);
}
.custom-control-label.toggle::after {
    background: $global-white;
}


// .mrnda-input-wrapper.suffix {
//   position: relative;
//   &::after {
//     content: 'px';
//     position: absolute;
//     top: 0;
//     right: 0;
//     color: var(--secondary-text-color);
//     padding: 0 1em;    
//   }
// }




.new-select {

    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    width: 100%;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;

    font-size: $base-font-size;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    height: $base-spacing-unit * 4;
    padding: 0 $base-spacing-unit * 1.5;
    line-height: $base-line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.new-select::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
  }

  .new-select,
.new-select:after {
  grid-area: select;
}

.new-select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }

/* Reset Select */
.belect > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: var(--secondary-background-color);
    background-image: none;
    padding: 0px 42px 0px 16px;
  }
  
  /* Custom Select */
  .belect {
    position: relative;
    display: flex;    
    height: 2.9em;
    line-height: 3;
    background: var(--secondary-background-color);
    overflow: hidden;
    border-radius: 4px;
    
    box-shadow: 0 0 0 1px var(--control-border-color), 0 0 0 1px var(--control-border-color) inset;

  }
  .belect > select {
    flex: 1;    
    color: var(--secondary-text-color);
    cursor: pointer;
  }
  /* Arrow */
  .belect::after {
    //content: '\25BC';
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    color: var(--secondary-text-color);
    padding: 0 1em;    
    cursor: pointer;
    opacity: 0.7;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
    height: 38px;
    background-repeat: no-repeat;
    background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-down.svg');
    background-size: 10px 10px;
    background-position: right 15px bottom 14px;
    filter: unquote("invert(var(--icon-mask))")  ;
  }
  /* Transition */
  .belect:hover::after {
    //color: #f39c12;
  }
  
  .form-control-themed{
    color: var(--secondary-text-color);
    background-color: var(--primary-background-color);
    border: none;
    box-shadow: 0 0 0 1px var(--control-border-color), 0 0 0 1px var(--control-border-color) inset;    

    &:disabled{
        color: var(--secondary-text-color);
        background-color: var(--primary-background-color);
    }
    &:focus{
        color: var(--secondary-text-color);
        background-color: var(--primary-background-color);
    }
}

/* Reset Select */
.themed-select > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: var(--secondary-background-color);
  background-image: none;
  padding: 0px 42px 0px 16px;
}

/* Custom Select */
.themed-select {
  position: relative;
  display: flex;    
  height: 2.9em;
  line-height: 3;
  background: var(--secondary-background-color);
  overflow: hidden;
  border-radius: 4px;
  
  box-shadow: 0 0 0 1px var(--control-border-color), 0 0 0 1px var(--control-border-color) inset;

}
.themed-select > select {
  flex: 1;    
  color: var(--secondary-text-color);
  cursor: pointer;
}
/* Arrow */
.themed-select::after {
  //content: '\25BC';
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  color: var(--secondary-text-color);
  padding: 0 1em;    
  cursor: pointer;
  opacity: 0.7;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
  height: 38px;
  background-repeat: no-repeat;
  background-image: url('../../assets/Images/Icons/interactive/icon-int-chevron-down.svg');
  background-size: 10px 10px;
  background-position: right 15px bottom 14px;
  filter: unquote("invert(var(--icon-mask))")  ;
}
/* Transition */
.themed-select:hover::after {
  //color: #f39c12;
}


%mrnda-tree-select,
.mrnda-tree-select {
  .rc-tree-select-selector { 
    @extend %mrnda-input-text;
    border: none !important; 
    padding: 0 !important;    
    .rc-tree-select-selection-search-input{
      background-color: var(--primary-background-color) !important;
    }
  }
  .rc-tree-select-selection-item {
    align-self: center;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    margin-top: 8px;
  }
  .rc-tree-select-arrow {
    margin-top: 8px;
    margin-right: 8px;
    color: var(--control-border-color) !important;
    display: none;
  }
  .rc-tree-select-show-arrow .rc-tree-select-arrow-icon {
    &:after {
      color: var(--control-border-color);
      border-top-color : (--control-border-color) !important;
    }    
  }
}