@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.miranda-support {
    font-family: 'Open Sans', sans-serif;  
   
    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 2em;
        margin: 0.67em 0;  
        line-height: 40px;  
        letter-spacing: -1px;        
    } 
    .form-group > label {
        font-weight: 600;
        color: var(--primary-text-color);        
        letter-spacing: -0.5px;  
        margin-bottom: 5px;
        font-size: 13px;
        line-height: 18px;      
    }
    
    .form-group > input {
        height: 28px;   
        color: var(--secondary-text-color);
        background-color: var(--primary-background-color);
        border: 1px solid var(--control-border-color);
        &:disabled{
            background-color: var(--control-border-color);
        } 
    }
    
    .richText {        
        border: 1px solid var(--control-border-color);
        border-radius: 6px;
        color: var(--secondary-text-color);
        background-color: var(--primary-background-color);
    }

    .richText .richText-editor {
        background-color: var(--primary-background-color);
        border-radius: 6px;
        border: none;
    }
    
    .richText .richText-editor:focus {
        border-left: #3498db solid 0px;
    }

    .file-area {
        width: 100%;
        position: relative;
        font-size: 12px;
    }
    
    
    .file-area input[type=file] {
        position: absolute;
        
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    
    }
    
    .file-area .file-dummy {
        
        padding: 23px 30px;
        border: 1px solid var(--control-border-color);
        border-radius: 5px;
        background-color: #fff;
        text-align: center;
        transition: background 0.3s ease-in-out;
    }
    
    .addfile {
        text-decoration: underline;
        color: blue;
    }
    
    .progress {
        background: blue;
        display: block;
        height: 2px;
        text-align: center;
        transition: width .3s;
        margin-bottom: 3px !important;
        margin-top: 3px !important;

        width: 0;
        max-width: 350px;
    }
    
    .progress.hide {
        opacity: 0;
        transition: opacity 1.3s;
        display: none;
    }
    
    .fileContainer {
        width: 350px;
        white-space: nowrap;
        padding-bottom: 7px;
        display: flex;
        flex-direction: column;
    
    }
    
    .fileContainer .title {
        width: 100%;
        display: block;
        clear: both;
        margin-top: 7px;
    }
    
    .title .name {
        max-width: 94%;
        float: left
    }
    
    .title .close {
        float: right;
        opacity: 0.7;
    }

    .description-textarea {
        min-height: 260px;
    }
    
    .form-container {
        padding-left: 200px        
    }
    
    .form-content {
        width: 70%
    }

    #successMessage {
        display: flex;
        justify-content: center;
    }
    
    .successDiv {
        display: flex;
        flex-direction: column;
        align-items : center
    }
    .error-text{
        color: red;
        font-size: 13px;
    }
}


.support-success-icon{
    color: green;
    font-size: 60px;
    opacity: 0.7;
    margin-bottom: 24px;
}
.support-success-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: var(--primary-text-color);
    letter-spacing: -0.5px;  
}