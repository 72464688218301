.attachment {
    overflow: visible;    
    transition: all 0.3s;
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover{
        border: 1px solid var(--border-color);
    }
    .attachment__body {
        display: flex;
        overflow: visible;
        flex: 0 0 auto;
        position: relative;
        width: 100%;
        padding: 8px;
        border: 0;
        border-radius: 4px;
        color: var(--primary-text-color);
        .attachment__text {
            flex: 1;
            display: inline;           
            font-size: 12px;
            color: var(--primary-text-color);
        }

        .attachment__icon {
            margin-right: 8px;
            color: var(--primary-text-color);
            transition: transform .3s;
        }
        .attachment__icon-rotate{
            transform: rotate(180deg);
        }

        &:hover {
            .attachment-delete {
                opacity: .6;
            }
        }
    } 
}
.attachments-list{
    display: flex;
    flex-direction: column;
}

.attachments-list-modal {
    width: max-content ;   /* Occupy the 90% of the screen width */
    max-width: 60vw;  
    min-width: 380px;  
}

.comment-attachment__collapsed{
   margin: 0px 4px 0px 48px
   
}
.reply-attachment__collapsed{
    margin: 0px 4px 0px 20px
 }
 .upload-spinner{
     background-color: var(--secondary-text-color);
 }
