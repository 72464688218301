$comment-background-color: #292b2f;

.comments-container__add-comment-box {
    left: 0;
    z-index: 10;
    // width: 346px;
    margin: 16px 0 16px 0px;

    .comment-form {
        flex: 1 0 auto;
        background: var(--secondary-background-color);
        position: relative;
        right: 0;
        border-radius: 4px;
        padding: 16px 12px 20px 4px;
        border: 1px solid var(--border-color);
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 4px 8px 12px 12px;
            height: auto;
        }
        &__avatar {
            flex: 0 36px;
            margin-top: 2px;
        }
        &__details {
            flex: 1;
            display: flex;
            flex-flow: row wrap;
            max-width: calc(100% - 60px);
            line-height: 15px;
        }
        &__author {
            flex: 1 0 100%;
            overflow: hidden;
            font-size: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:  var(--primary-text-color);
            margin: 0 0 2px 0px;
        }
        &__info {
            flex: 1 1 50%;
            display: inline-block;
            font-size: 10px;
            color:  var(--primary-text-color);
            display: flex;
            align-items: flex-end;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 65px;
            align-items: center;
        }
        &__content {           
            margin: 12px 4px 12px 48px;
            & > textarea {
                background-color: var(--primary-background-color);
                border: none;
                color:  var(--primary-text-color);
                width: 100%;
                height: 32px;
                resize: none;
                overflow: hidden auto;
                min-height: 50px;
                &:focus{
                    color: var(--primary-text-color);
                    background-color: --primary-background-color;
                }

                
            }
        }

        &__attachments{            
            display: flex;
            flex-direction: column;
            margin: 12px 0px 18px 0px;
        }
        &__footer {
            display: flex;
            flex: 1 0 auto;
            justify-content: flex-start;
            margin: 0 18px 0px 48px;
            min-height: 52px;
            &__action-buttons{
                
                .button-comment-form--textual {
                    position: relative;
                    color:  var(--primary-text-color);
                    font-size: 14px;
                    background-color: transparent;
                    border: 1px solid #b4b4b4;
                    border-radius: 2px;
                    height: 32px;
                    line-height: 30px;
                    min-width: 68px;
                    padding-left: 16px;
                    padding-right: 16px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    min-width: initial;
                    min-width: 90px;
                }
            }
        }
    }
}

.comments-container__edit-comment-box {
    background-color: var(--secondary-background-color);
}
// Document Viewer Comment Box

.mrnda-document-comment-box.comments-container__add-comment-box {
    left: 0;
    z-index: 10;
    // width: 346px;
    margin: 8px 0 8px 0px;
    padding: 8px;
    .comment-form {
        flex: 1 0 auto;
        background: $mrnda-video-editor-body-color;
        position: relative;
        right: 0;
        border-radius: 4px;
        padding: 16px 12px 20px 4px;
        border: 2px solid var(--primary-color-shade-2);
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 4px 8px 12px 12px;
            height: auto;
        }
        &__avatar {
            flex: 0 36px;
            margin-top: 2px;
        }
        &__details {
            flex: 1;
            display: flex;
            flex-flow: row wrap;
            max-width: calc(100% - 60px);
            line-height: 15px;
        }
        &__author {
            flex: 1 0 100%;
            overflow: hidden;
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:  $mrnda-video-editor-font-color;
            margin: 0 0 2px 0px;
        }
        &__info {
            flex: 1 1 50%;
            display: inline-block;
            font-size: 10px;
            color:  $mrnda-video-editor-font-color;
            display: flex;
            align-items: flex-end;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 65px;
            .comment__date-area__page-number{
                font-size: 12px;
            }
            .comment__date-area__annObjectImage{
                bottom: 1px
            }
        }
        &__content {
            margin: 16px 4px 16px 48px;
            & > textarea {
                background-color: $mrnda-video-editor-element-color;
                border: none;
                color:  $mrnda-video-editor-font-color;
                width: 100%;
                height: 32px;
                resize: none;
                overflow: hidden auto;
                min-height: 50px;
                &:focus{
                    color: $mrnda-video-editor-font-color;
                    background-color: $mrnda-video-editor-element-color;
                }
            }
        }
        &__footer {
            display: flex;
            flex: 1 0 auto;
            justify-content: flex-start;
            margin: 0 18px 0px 48px;
            min-height: 52px;
            &__action-buttons{
                .button-comment-form--primary {
                    font-size: 12px;
                    border-radius: 2px;
                    height: 28px;
                    line-height: 26px;
                    padding-left: 16px;
                    padding-right: 16px;
                    outline: 0;
                    color: #fff;
                    border: 0;
                    background-color: var(--primary-color);
                    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
                    text-overflow: clip;
                    min-width: 70px;
                }
                .button-comment-form--textual {
                    position: relative;
                    color:  var(--primary-text-color);
                    font-size: 14px;
                    background-color: transparent;
                    border: 1px solid #b4b4b4;
                    border-radius: 2px;
                    height: 32px;
                    line-height: 30px;
                    min-width: 68px;
                    padding-left: 16px;
                    padding-right: 16px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    min-width: initial;
                    min-width: 90px;
                }
            }
        }
    }
}

.attachment-details__container {
    float: right;    
    margin: 6px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--primary-background-color);    
    border-radius: 4px; 
    -webkit-box-shadow: 0px 0px 3px 0px rgba(136, 55, 55, 0.10); 
    box-shadow: 0px 0px 3x 0px rgba(0,0,0,0.10);   
}
.attachment-details__container label{
    font-size: 9px;
    color: var(--secondary-text-color);
}

.attachment-output__list-item {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: end;
    margin: 0 auto 25px;
    list-style: none;
}

.attachment-output__list-item-container {
    display: flex;
    align-items: start;        
    margin: 0px 8px 0px 8px;
    color: var(--icon-text-color);
    flex-direction: column;
}

.attachment-output__list-item-name {
    // margin-bottom: 10px;
    margin-left: 8px;    
    color: var(--primary-text-color);
    font-size: 12px;
    font-weight: normal;    
    line-height: 1.5;
    display: inline-block;
    width: 90%;
}
.attachment-output__list-item-status {
    float: right;
    margin: 0px -4px 0px 1px;
    color: #4eb3b4;
    border-color: #4eb3b4;
}
.attachment-output__list-item-status__label{
    margin: 0px 1px 0px 2px;
    color : var(--primary-text-color);
}
.attachment-output__list-item--is-complete {
    display: flex;
    align-self: center;
    width: 10px;
    height: 10px;
}
.attachment-output__list-item-footer__size {
  float: right;
  margin-left: 2px;
  color : var(--secondary-text-color);
  label{
    margin: 0px;
}

}
.attachment-output__list-item-footer__time {
    float: right;
    margin-right: 2px;
    label{
        margin: 0px;
    }
}
.attachment-output__list-item-header {
    width: 100%;
    margin: 8px 0px 0px 0px;   
    display: flex; 
}
.attachment-output__list-item-middle-area {
    margin: 2px 0px 0px 0px;
}
.attachment-output__list-item-footer {     
    font-size: 11px;
    color: #3d4852;
}

.attachment-icon {
    border: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;    
    transition: opacity .3s ease, background-color .3s ease;
    // border-radius: $base-border-radius;
    background-color: var(--secondary-background-color);
    border-radius: 15px;
    color : var(--secondary-text-color);
    & > i{
        font-size: 16px;
    }
    &:hover {
      opacity: 1;
      background-color: rgba($global-black,.05);
    }

    &:focus {
      outline: none;
    }
  }
  .progress-bar-attachment{
    height: 2px;
    border-radius: 0px;
    background-color: var(--control-border-color) !important;
    .progress-bar{
        background-color: var(--primary-color) !important;
    }
  }

.attachment-close {

    color: var(--primary-text-color);
    opacity: .6;
    transition: all .3s;

    &:hover {
        opacity: 1;
    }

}


.attachment-delete {

    color: var(--primary-text-color);
    opacity: 0;
    transition: all .3s;
    margin-left: 16px ;
    &:hover {
        opacity: 1;
    }

}

.comment__edit-indicator {
    color: var(--secondary-text-color);
    font-size: 12px;
    opacity: 0.8;
}

.comment-list-lite {    
    margin: 0px 0px 16px 12px;
}

.annotation-action-button{
    font-size: 12px;        
    line-height: 26px;
    padding-left: 16px;
    padding-right: 16px;    
    color: var(--secondary-text-color);
    border: 1px solid var(--secondary-text-color);
    border-radius: 4px;                                   
    text-overflow: clip;
    min-width: 70px;
    background: transparent;

}

.progress-bar-attachment--mobile{
    height: 4px;
    border-radius: 0px 0px 4px 4px;
    background-color: var(--control-border-color) !important;
    .progress-bar{
        background-color: var(--primary-color) !important;
    }
}