$base-border-radius: 4px;

.workflow-row{
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    font-size: 14px;
    border-radius: $base-border-radius;
    position: relative;
}

.workflow-row-backdrop {
    height: 100%;
    width: 100%;
    background-color: var(--secondary-text-color);
    position: absolute;
    opacity: 0.07;
    border-radius: $base-border-radius;
}

.workflow-row-content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    border-radius: $base-border-radius;
    width: 100%;  
}

.workflow-row-content-collapsed {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 52px;
    width: 100%;

}

.workflow-row-content-expanded {    
    padding: 16px;
}



.icon-separator {
    height: 18px;
    width: 1px;
    align-items: center;
    background-color: var(--secondary-text-color);    
}

.workflow-row-actions-host {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.workflow-row-name {
    margin-left: 16px;
}

.workflow-row-action-icon {
    transition: all 0.3s;
}
.workflow-row-action-icon-expanded {
    transform: rotate(90deg);
}

.workflow-row-content-hr {
    width: 100%;
    margin: 0px;
    opacity: 0.5;
}

.workflow-config-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.workflow-config-cell-enable {
    width: 40%;
    display: flex;
    align-items: center;
}

.workflow-config-cell-group {
    width: 60%;    
}
.workflow-config-cell-enable-label {
    padding-left: calc(17px + 12px);
    width: auto;
}

.workflow-config-visibility__check {
    padding: 0px !important;
    height: 17px;
    width: 17px;
    margin-bottom: 0px !important;
}