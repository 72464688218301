%mrnda-card,
.mrnda-card {
  background: var(--primary-background-color);
  border-radius: $base-border-radius;
  box-shadow: 0 0 0 1px var(--border-color);
  overflow: hidden;
  position: relative;
}

%mrnda-card--shadow,
.mrnda-card--shadow {
  box-shadow: 0 0 0 1px rgba($global-black,.1),
              0 4px 15px 0 rgba($global-black, .075);
}

%mrnda-card--hover,
.mrnda-card--hover {
  transition: box-shadow .3s ease;

  &:hover {
    box-shadow: 0 0 0 1px rgba($global-black,.1),
                0 0 0 $base-spacing-unit rgba($global-brand, .1);
  }
}

.mrnda-card__image-container {
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio

  position: relative;
  background-color: rgba($global-black,.1);
  box-shadow: 0 -1px 0 0 var(--border-color) inset;
}

.mrnda-card__locked{
  display: flex;  
  background-color: rgba($color: #000000, $alpha: .5);
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  & span{
    font-size: 16px;        
  }
  & i {
    margin-right: 8px;
  }
}
.folder-details {
  background-color: red;
}
.mrnda-card__proof-status {
  margin-left: 15px;
  margin-bottom: 15px;
}
.mrnda-card__info{
  right: 12px;
  position: absolute; 
  bottom: 16px; 
  opacity: 0.8;
  display: flex;
  flex-direction: row-reverse;
  transition: opacity 0.3s;
  align-items: end;
  & i {
    color: var(--primary-text-color); 
    margin-left: 8px; 
    font-size: 16px;
  }
  &-contents{
    background-color: var(--primary-text-color);
    display: none;
    opacity: 0;    
    flex-direction: column;
    opacity: 1;
    padding: 8px;
    border-radius: 4px;
    color: var(--primary-background-color);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.20);
  }
  

  &:hover {
    opacity: 1;
    .mrnda-card__info-contents {
      display: flex;
      opacity: 1;
    }
  }
}

.mrnda-card__info-has-recent{
  & i {
    color: #0091FF;
  }
}

.mrnda-card__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  height: calc(100% - #{$base-spacing-unit});
  width:  calc(100% - #{$base-spacing-unit});
  top: $base-spacing-unit*.5;
  left: $base-spacing-unit*.5;
  & i {
    color: var(--icon-text-color);
    font-size: 72%;
    opacity: 0.7;
  }
}

.mrnda-card__image-container--folder {
  background-color: var(--primary-background-color);
  background-image: none;

  &::after {
    content: '';
    display: block;
    width: $base-int-icon-size*4;
    height: $base-int-icon-size*4;
    top: calc(50% - #{$base-int-icon-size*2});
    left: calc(50% - #{$base-int-icon-size*2});
    background-size: $base-int-icon-size*4 $base-int-icon-size*4;
    background-image: url('../../assets/Images/Icons/interactive/icon-int-folder.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    opacity: .2;  
    filter: unquote("invert(var(--icon-mask))")  
  }
}
