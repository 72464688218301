// .document-js .vjs-progress-control { display: none; }
// .document-js .vjs-control-bar { justify-content: flex-end;}

// $mrnda-document-editor-body-color: #292b2f;
// $mrnda-document-editor-element-color: #3f4346;
// $mrnda-document-editor-font-color: white;

$mrnda-document-editor-body-color: #f7f8f9;
$mrnda-document-editor-element-color: white;
$mrnda-document-editor-font-color: black;

@mixin neumorphic-card {
    // border-radius: 10px;
    background: var(--secondary-background-color);
    // box-shadow: -20px 12px 12px 2px #1d1f22, 3px -5px 20px #292b30;
}

.mrnda-document-editor-body {
    // padding: 20px 20px 20px 20px;
    height: 100%;
    background-color: var(--primary-background-color);
    position: relative;
}

.mrnda-document-editor-container {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    // width: 1430px;
    // margin: 0px 20px 0px 0px;
    height: calc(100% - 20px);
    overflow: hidden;
}
.mrnda-editor-sidebar-new-comment-banner {
    background-color: var(--primary-background-color);
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-radius: 6px;
    -webkit-box-shadow: 1px 1px 20px -8px rgba(0, 0, 0, 0.26);
    box-shadow: 1px 1px 20px -8px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    &__text {
        color: var(--primary-color);
        text-align: center;
        font-size: 14px;
    }
}
.mrnda-document-editor-sidebar {
    padding: 20px 10px 4px 10px;
    flex: 1 0 345px;
    overflow: hidden auto;
    // border-left: 1px lighten(grey, 25%) solid;
    margin: 0 0 0 1px;
    @include neumorphic-card;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(----border-color);
    &__header {
        flex-flow: row wrap;
        width: auto;
        &__container {
            display: flex;
            justify-content: space-between;
        }
        &__text {
            color: var(--primary-text-color);
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
            word-break: break-word;
        }
    }
    &__comments {
        overflow: hidden auto;
    }
    &__tabs {
        padding: 12px 0;
        &__item {
            margin-left: 0;
            margin-right: 20px;
            padding: 12px 0;
            cursor: pointer;
        }
    }
}

.mrnda-document-editor-sidebar-table-container {
    // width: 300px;
}

.mrnda-document-editor-area {
    flex-grow: 4;
    // padding: 0px 32px 32px 32px;
    margin: 0px 0px 0px 0px;
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;

    &-body {
        display: flex;
        overflow: hidden;
        height: 100%;
        &-thumbnail-area {
            display: flex;
            flex-direction: column;
            flex: 2;
            max-width: 140px;
            background-color: var(--secondary-background-color);
            align-items: center;
            padding: 20px 0;
            overflow: hidden hidden;

            &:hover{
                overflow: overlay;
            }
        }
        &-page-wrapper {
            display: flex;
            flex: 7;
            height: auto;
            align-items: center;
            flex-direction: column;
            padding: 20px 0;
            transition: transform 0.3s ease;
            overflow: auto;
           
        }
    }
}

.mrnda-document-editor-area-canvas-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 25px 0;
    width: fit-content;
    margin: 0 auto 10px 0;
    align-items: center;
}

//NOTE :Non themed seekbar should be removed when full dark mode is supported
//=============================Start======================================
.mrnda-document-editor-area-controls-seekbar-fixed-theme {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    width: 100%;
    height: 15px;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    margin: 20px 0 0 0px;
}
.mrnda-document-editor-area-controls-seekbar-fixed-theme::-webkit-progress-bar {
    background-color: $mrnda-document-editor-element-color;
    // box-shadow: inset 0px 0px 10px 1px rgba(117, 182, 255, 0.4),
    //     0px 0px 20px rgba(117, 182, 255, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    border-radius: 2px;
}
.mrnda-document-editor-area-controls-seekbar-fixed-theme::-webkit-progress-value {
    border-radius: 2px;
    background: rgb(0, 255, 67);
    box-shadow: inset 0px 0px 10px 2px rgba(117, 255, 121, 0.5),
        0px 0px 20px rgba(117, 214, 133, 0.5);

    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}
.mrnda-document-editor-area-controls-seekbar-fixed-theme::-moz-progress-bar {
    border-radius: 2px;
    background: rgb(0, 255, 67);
    box-shadow: inset 0px 0px 10px 2px rgba(117, 255, 121, 0.5),
        0px 0px 20px rgba(117, 214, 133, 0.5);

    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}
//=============================End==================================


.mrnda-document-editor-area-body-thumbnail-area{
    z-index: 2;
    max-width: 160px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.19);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.19);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.19);
    
    &-thumbnail {        
        display: flex;
        flex-direction: column;        
        align-items: center;
        justify-content: center;                                                                                    
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 8px;
        margin-top: 2px;
        border: 1px solid #cacaca;
        span {
            font-size: 8px;  
            color : black;
            background-color: white;
            width: 100%;
            text-align: center;
          }
        &__selected {        
            
            border: 1px solid var(--primary-color);
            span {
                color : white;
                background-color: var(--primary-color);
            }
        }
            
        &:hover{
            background-color: var(--primary-color-shade-2);
            border: 1px solid var(--primary-color-shade-2);
            cursor: pointer;
            span {
                color : white;
            }
        }
    }
}


.mrnda-document-editor-area-toolbox {
    // border: black 1px solid;
    display: flex;
    position: sticky;
    align-items: center;
    height: 52px;
    top: 0;
    z-index: 3;
    padding: 0px 25px 0px 25px;
    justify-content: space-evenly;
    border-bottom: 1px solid var(--border-color);
    @include neumorphic-card;
    &-contributors {        
        display: flex;
        justify-content: flex-start;
        flex: 1;           
        
    }
    &-shapes {        
        display: flex;
        justify-content: center; 
        flex: 1;       
        &--item {
            color: var(--primary-text-color);
            font-size: 22px;
            margin: 0 15px 0 0px;
            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
            &.active {
                color: var(--primary-color);
            }
        }
    }
    
    &-tools {        
        display: flex;
        justify-content: flex-end;
        flex: 1;           
        &--item {
            color: var(--primary-text-color);
            font-size: 22px;
            margin: 0 15px 0 0px;
            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
            &.active {
                color: var(--primary-color);
            }
        }
    }


}


.document-canvas-selected{
    -webkit-box-shadow: 0px 5px 40px -15px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 40px -15px rgba(0,0,0,0.2);
}

.document-canvas{
    // border: 2px solid #f3f3f3;
}
.mrnda-document-editor-area-pages{
    transform-origin: center top;
    transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), transform-origin 0.3s;
}

.mrnda-input-select-document-zoom{
    height: 24px;
    width: 100px;
    color: var(--primary-text-color) !important;
    padding: 0px 42px 0px 8px !important;
}

.zoom-select{
    height: 27px;
    width: 100px;    
    font-size: 16px;
    box-shadow: none;
    border: 1px solid var(--primary-text-color);
    &::after {
        background-position: right 9px bottom 20px;
    }
}


// Doucment viewer css 
.mrnda-document-viewer-body {
    // padding: 20px 20px 20px 20px;
    height: calc(100vh - 20px);
    background-color: var(--primary-background-color);
}

.mrnda-document-viewer-container {
    top: 0px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    // width: 1430px;
    // margin: 0px 20px 0px 0px;
    height: 100%;
}

.mrnda-document-viewer-area {
    flex-grow: 4;
    // padding: 0px 32px 32px 32px;
    margin: 0px 0px 0px 0px;
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;

    &-body {
        display: flex;
        overflow: hidden;
        height: 100%;
        &-thumbnail-area {
            display: flex;
            flex-direction: column;
            flex: 2;
            max-width: 140px;
            background-color: var(--secondary-background-color);
            align-items: center;
            padding: 20px 0;
            overflow: hidden auto;
        }
        &-page-wrapper {
            display: flex;
            flex: 7;
            height: auto;
            align-items: center;
            flex-direction: column;
            padding: 20px 0;
            transition: transform 0.3s ease;
            overflow: auto;
            
        }
    }
}

.mrnda-document-viewer-area-pages{
    transform-origin: top center;
    transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.document-viewer-selected{
    -webkit-box-shadow: 0px 5px 40px -15px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 40px -15px rgba(0,0,0,0.2);
}

.document-viewer{
    border: 2px solid #f3f3f3;
}

.document-editor-canvas-textbox-container{
    display: flex;
    background: var(--secondary-background-color);
}

.speech-bubble {
	position: relative;
	background: #00aabb;
	border-radius: .4em;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 24px solid transparent;
	border-right-color: #00aabb;
	border-left: 0;
	margin-top: -24px;
	margin-left: -24px;
}

.document-editor-annotation-color-picker {
    position: absolute;
    top: 50px;
    background:var(--primary-background-color);
    padding: 12px;
    z-index: 998;
    margin-left: 20px;
    border-radius: 4px 10px 10px 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.09);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.09);

    &__opacity {
        &--range{
            width: 100%;
        }
    }
    &__colors {
        .color-palette{
            display: flex;
            &__color-outer{
                width: 22px;
                height: 22px;
                border-radius: 3px;
                border: 1px solid transparent;
                margin: 2px;
                cursor: pointer;
                &.selected{
                    border: 1px solid var(--primary-color-shade-2);
                }
            }
            &__color-inner{
                width: 20px;
                height: 20px;
                border-radius: 3px;
                border: 1px solid #1d1e22;
            }
        }
    }
}

.contributor-avatar--secondary--small{

    font-size: inherit;
    font-weight: bold;
    color: white;

}


.contributor-color-picker{
    display: flex;
    max-width: 204px;
    flex-wrap: wrap;
    &__color-outer{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin: 2px 4px 2px 4px;
        cursor: pointer;
        &.selected{
            border: 1px solid var(--primary-color-shade-2);
        }
    }
    &__color-inner{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #1d1e22;
    }
}

.mrnda-proof-editor-contributors{
    display: flex;
    justify-content: flex-start;
    flex: 1;           
    // &--item {
    //     i{
    //         height: 24px;
    //         width: 24px;
    //         color: white;
    //         font-size: 16px;
    //         text-align: center;
    //         margin: 6px 0px 0px 2px;
    //     }
    //     height: 28px;
    //     width: 28px;
    //     border-radius: 14px;
    //     background-color: red;
    //     color: var(--primary-text-color);
    //     font-size: 22px;
    //     margin: 0px 15px 0 0px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     transition: .6s;
    //     &:hover {
    //         color: var(--primary-color);
    //         cursor: pointer;
    //     }
    //     &.active {
    //         color: var(--primary-color);
    //     }
    // }

    &--item {
        i{
            height: auto;
            width: auto;
            color: white;
            font-size: inherit;
            text-align: center;            
        }
        height: 30px;
        width: 30px;
        border-radius: 20px;
        background-color: red;
        color: var(--primary-text-color);
        font-size: 11px;
        margin: 0px -6px 0 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        border: 3px solid var(--secondary-background-color);
        &:hover {
            color: var(--primary-color);
            cursor: pointer;
            filter: brightness(1.15);
            z-index: 3;            
        }
        &.active {
            color: var(--primary-color);
        }
    }
}

.contributor-more {
    display: flex;
    justify-content: flex-start;
    flex: 1;           
    // &--item {
    //     span{
    //         color: var(--primary-text-color);
    //         font-size: 12px;
    //         text-align: center;
    //         font-weight: bold;
    //     }
    //     height: 28px;
    //     width: 28px;
    //     border-radius: 14px;
    //     background-color: var(--control-border-color);
    //     color: var(--primary-text-color);
    //     font-size: 22px;
    //     margin: 0px 15px 0 0px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border: 1px solid var(--primary-color);
    //     transition: filter 300ms;
    //     &:hover {            
    //         cursor: pointer;
    //         filter: brightness(1.3);
    //     }
    //     &.active {
    //         color: var(--primary-color);
    //     }
    // }
    &--item {
        span{
            color: var(--primary-text-color);
            font-size: 12px;
            text-align: center;
            font-weight: bold;
        }
        height: 36px;
        width: 36px;
        border-radius: 20px;
        background-color: var(--control-border-color);
        color: var(--primary-text-color);
        font-size: 22px;
        margin: 0px -6px 0 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--secondary-background-color);
        transition: filter 300ms;
        z-index: 2;
        &:hover {            
            cursor: pointer;
            filter: brightness(1.3);
        }
        &.active {
            color: var(--primary-color);
        }
        &--inner{
            height: 31px;
            width: 31px;
            border-radius: 20px;
            position: absolute;
            border: 1px solid var(--primary-color);
        }
    }
    
}

.contributors-collapsed {   
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.contributors-collapsed-dropdown {
    display: flex;
    position: absolute;
    top: 40px;    
    z-index: 3;
    width: auto;
    transform: translateX(45%); 
    padding: 1rem;    
    border-radius: 8px;
    transition: height 300ms ease;   
}

.contributors-space {
    height: 20px;
    width: 1px;
    background-color: var(--secondary-text-color);
    margin:0px 9px 0px 14px;
    border-radius: 4px;
    align-self: center;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}



[data-title]:after {
    content: attr(data-title);
    background-color: var(--secondary-background-color);
    color: var(--secondary-text-color);
    font-size: 120%;
    position: absolute;
    padding: 4px 8px 4px 8px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    border-radius: 6px;
    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.15);
    border: 1px solid var(--control-border-color);
}
[data-title] {
    position: relative;
}


.contributors-collapsed-dropdown-inner{
    display: inline-flex;
    background-color: var(--primary-background-color);
    padding: 1rem;
    border-radius: 8px;
    transition: height 300ms ease;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.15);
}

.annotation-comment-arrow{
    transition: opacity 0.05s;
}
.annotation-comment-arrow-disabled{
    opacity: 0;
}
.annotation-comment-arrow-enabled{
    opacity: 1;
}

.editor-loader{
    transition: opacity 0.05s;
}
.editor-loader-hide{
    opacity: 0;
}
.editor-loader-show{
    opacity: 1;
}


[data-fabric-hiddentextarea] {
    position: absolute !important; 
    top: 0 !important; 
    left: -9999px !important;
}

.text-tools-icon {
    color: var(--secondary-text-color);
    font-size: 16px;   
    margin-right: 8px; 
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    transition: all 150ms ease;
    transition-property: color, background;

    &:hover {
        color: var(--primary-color);
        cursor: pointer;
    }
    &.active {
        color: var(--primary-color);
        background: var(--primary-background-color);
    }
  
}

.documentImageViewer{
    transition: opacity .5s;
}

.skeleton {    
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: var(--control-border-color);
    }
    
    100% {
        background-color: var(--secondary-background-color);
    }
}

.cursor-for-hand-tool-selected {
    cursor: grab;
}
.cursor-for-hand-tool-dragging {
    cursor: grabbing;
}

// 0.8s linear 0s infinite alternate none running animation-1t6jf1h

.progressive-img-loading {
    filter: blur(20px);
    clip-path: inset(0);
  }
.progressive-img-loaded {
    filter: blur(0px);
    transition: filter 1.5s linear;
}


.webviewer-loading {
    filter: blur(8px);
    clip-path: inset(0);
  }
.webviewer-loaded {
    filter: blur(0px);
    transition: filter 0.3s linear;
}

.viewport-image {
    height: 100%;
    width: 100%;
    position: absolute;
    outline: 1px solid var(--icon-text-color);
    transition: opacity 0.5s;  

}