.print-summary-content {
    width: 100%;
    max-width: 1024px;
    margin: 0px auto 0px;
    padding: 5px;
    .print-summary-header-container {
        position: relative;
        margin: 10px 0px 20px;
        .print-summary-header {
            color: var(--primary-text-color);
            // font-family: 'Proxima Nova';
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
            word-break: break-all;
            margin: 0px;
            padding: 0px 220px 0px 0px;
            .print-summary-version {
                color: var(--secondary-text-color);
                font-size: 16px;
                padding-left: 16px;
            }
            .version-current {
                color: var(--primary-text-color);
            }
            .version-all {
                opacity: 0.6;
            }
        }
        .print-summary-date {
            font-size: 12px;
            color: var(--secondary-text-color);
            line-height: 16px;
            height: 16px;
            position: absolute;
            right: 0px;
            top: 20px;
        }
    }
    .print-summary-header-body-container {
        .section-header {
            margin-top: 24px;
            position: relative;
        }
        .section-body {
            padding-left: 24px;
            color: var(--secondary-text-color);
            .proof-details-content {
                // font-family: 'Proxima Nova';
                font-size: 14px;
                width: 100%;
                display: flex;
                .proof-thumbnail {
                    align-self: flex-start;
                    flex: 1 1 180px;
                    svg {
                        fill: var(--primary-text-color);
                        margin-right: 64px;
                        width: 116px;
                    }
                }
                .proof-details {
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1 1 100%;
                    .proof-info-col-1 {
                        width: 50%;
                    }
                    .proof-info-col {
                        box-sizing: border-box;
                        padding-right: 64px;
                        .proof-info-col-cell {
                            display: block;
                            margin-bottom: 16px;
                            .proof-details-row--label {
                                display: block;
                                color: var(--primary-text-color);
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: bold;
                            }
                            .proof-details-row--value {
                                color: var(--secondary-text-color);
                                display: block;
                                line-height: 20px;
                                min-height: 20px;
                                overflow-wrap: break-word;
                                padding: 0px;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .section-body {
        padding-left: 24px;
        color: var(--secondary-text-color);
    }
    .section-header {
        color: var(--primary-text-color);
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 24px;
        word-break: break-word;
        overflow-wrap: break-word;
        user-select: none;
        margin-top: 24px;
        position: relative;
        z-index: 1;
    }
    .section-header:before {
        content: '';
        height: 1px;
        left: 0px;
        right: 0px;
        top: 20px;
        position: absolute;
        z-index: -1;
        border-bottom: 1px solid var(--control-border-color);
    }
    .section-collapsible .section-header .section-header-title {
        cursor: pointer;
        display: inline-block;
        padding-right: 8px;
        background: var(--secondary-background-color);
    }
    .comment {
        width: 100%;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        page-break-inside: avoid;
    }
    .comment.display-thumbnail {
        margin-bottom: 16px;
        padding-bottom: 12px;
        border-bottom: 1px dashed var(--control-border-color);
    }

    .comment.display-thumbnail .comment-content {
        max-width: calc(50% - 16px);
    }
    .comment-replies {
        margin-left: 10px;
    }
    .comment-content .comment-header,
    .comment-reply .comment-header,
    .comment-content .comment-reply-header,
    .comment-reply .comment-reply-header {
        color: var(--primary-text-color);
        margin-bottom: 12px;
        font-weight: 700;
    }
    .comment-content .comment-header .comment-number,
    .comment-reply .comment-header .comment-number,
    .comment-content .comment-reply-header .comment-number,
    .comment-reply .comment-reply-header .comment-number {
        color: var(--primary-text-color);
        font-size: 24px;
        padding-right: 8px;
    }
    .comment-reply-status {
        font-size: 10px;
        padding: 4px;
        border-radius: 3px;
        margin-right: 10px;
    }
    .comment-content .comment-header .comment-author,
    .comment-reply .comment-header .comment-author,
    .comment-content .comment-reply-header .comment-author,
    .comment-reply .comment-reply-header .comment-author,
    .comment-content .comment-header .comment-reply-header-text,
    .comment-reply .comment-header .comment-reply-header-text,
    .comment-content .comment-reply-header .comment-reply-header-text,
    .comment-reply .comment-reply-header .comment-reply-header-text {
        font-size: 14px;
        padding-right: 8px;
        word-break: break-all;
    }
    .comment-content .comment-created,
    .comment-reply .comment-created,
    .comment-content .comment-reply-created,
    .comment-reply .comment-reply-created {
        font-size: 12px;
        font-weight: 400;
        color: var(--primary-text-color);
    }
    .comment-content .comment-resolved,
    .comment-reply .comment-resolved {
        margin-bottom: 20px;
    }
    .comment-content .comment-resolved--icon,
    .comment-reply .comment-resolved--icon {
        display: inline-block;
        vertical-align: middle;
        width: 14px;
        height: 14px;
        margin-right: 8px;
    }
    .comment-content .comment-resolved--text,
    .comment-reply .comment-resolved--text {
        color: rgb(255, 255, 255);
        display: inline-block;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        text-transform: uppercase;
        vertical-align: middle;
        background: rgb(139, 195, 74);
        border-radius: 10px;
        padding: 0px 8px;
    }
    .comment-content .comment-text,
    .comment-reply .comment-text,
    .comment-content .comment-reply-text,
    .comment-reply .comment-reply-text {
        color: rgb(84, 84, 84);
        font-size: 14px;
        margin-bottom: 24px;
        white-space: pre-wrap;
    }
    .comment-content .comment-header,
    .comment-reply .comment-header,
    .comment-content .comment-reply-header,
    .comment-reply .comment-reply-header {
        color: var(--primary-text-color);
        margin-bottom: 12px;
        font-weight: 700;
    }
    .comment-content .comment-header .comment-reply-number,
    .comment-reply .comment-header .comment-reply-number,
    .comment-content .comment-reply-header .comment-reply-number,
    .comment-reply .comment-reply-header .comment-reply-number {
        color: var(--primary-text-color);
        font-size: 16px;
        padding-right: 8px;
    }
    .comment-content .comment-header .comment-author,
    .comment-reply .comment-header .comment-author,
    .comment-content .comment-reply-header .comment-author,
    .comment-reply .comment-reply-header .comment-author,
    .comment-content .comment-header .comment-reply-header-text,
    .comment-reply .comment-header .comment-reply-header-text,
    .comment-content .comment-reply-header .comment-reply-header-text,
    .comment-reply .comment-reply-header .comment-reply-header-text {
        font-size: 14px;
        padding-right: 8px;
        word-break: break-all;
    }
    .comment-content .comment-created,
    .comment-reply .comment-created,
    .comment-content .comment-reply-created,
    .comment-reply .comment-reply-created {
        font-size: 12px;
        font-weight: 400;
        color: var(--primary-text-color);
    }
    .comment-content .comment-text,
    .comment-reply .comment-text,
    .comment-content .comment-reply-text,
    .comment-reply .comment-reply-text {
        color: var(--secondary-text-color);
        font-size: 14px;
        margin-bottom: 24px;
        white-space: pre-wrap;
    }
    .comment-preview {
        width: calc(50% - 16px);
        padding-left: 16px;
    }
    .comment-preview-item {
        width: 100%;
    }
    .comment-preview-item .comment-preview-item--source-name {
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 12px;
        color: rgb(0, 0, 0);
    }
    .comment-preview-item .comment-preview-item--content {
        border-width: 1px;
        border-style: solid;
        border-color: var(--control-border-color);
    }
    .comment-preview-item .comment-preview-item--content .comment-preview-item--header {
        width: 100%;
        line-height: 30px;
        font-size: 12px;
        padding: 0px 8px;
        overflow: hidden;
    }
    .comment-preview-item .comment-preview-item--content .comment-preview-item--header div {
        display: inline-block;
        margin: 0px 4px;
    }
    .comment-preview-item .comment-preview-item--content img {
        display: block;
        max-width: 100%;
        margin: auto;
    }
    .comment-preview-item .comment-preview-item--content .comment-preview-item--header strong {
        color: var(--primary-text-color);
    }
}

.transition-opacity {
    transition: opacity 1s;
}


.annotation-report-progress-bar {
    width: calc(100% + 40px);
    height: 4px;
    background-color: var(--secondary-background-color);    
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px;
    transition: all .3s;
  }
.annotation-report-progress-bar-fill {
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.3s ease-in-out;
}

.annotation-background,
.annotation-background-image {
    height: 100%;
    width: 100%;
}

@media print {    
    .print-summary-content .print-summary-header-container {
        // margin: 30px 0px 30px;
    }
    
}