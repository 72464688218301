.mrnda-video-canvas__player-area{
    &__container {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 25px 0;
        min-height: 100%;
        height: 100%;
        width: 100%;
        margin: 0 auto 10px 0;
        align-items: center;
        &--canvas-wrapper {
        }
        &--video {
            display: block;
        }
    }
    &__caption{
        padding: 15px;
        text-align: center;
        max-height: 80px;
        min-height: 80px;
        &--text{
            background:#0000008c;
            color: white;
            padding: 8px;
            line-height: 2.0;
        }
    }
}
