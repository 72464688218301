$comment-background-color: #292b2f;

// $mrnda-video-editor-body-color: white;
// $mrnda-video-editor-element-color:#f7f8f9;
// $mrnda-video-editor-font-color: black;

.mrnda-comment-container {
    %comment,
    .comment {
        margin: 0 0 8px 0;
        font-size: 13px;
        line-height: 18px;
        background: var(--secondary-background-color);
        position: relative;
        opacity: 1;
        border-radius: 4px;        
        transition: max-height 0.3s ease-in;
        height: auto;
        padding: 16px 12px 20px 4px;
        border: 1px solid var(--border-color);
        
        //TODO: Need to look into this, scrollbar visible momentarily.
        // overflow-y: auto;
        &:hover {
            cursor: pointer;
            border: 0.1px solid #444444;
        }

        &__header {
            margin: 4px 8px 12px 12px;
            position: relative;
            justify-content: flex-start;
            flex-direction: row;
            display: flex;
            -webkit-box-direction: normal;

            .comment__avatar {
                flex: 0 36px;
                margin-top: 2px;
                .avatar {
                    position: relative;
                    border-radius: 50%;
                    font-size: 10px;
                    line-height: 30px;
                    text-align: center;
                    text-transform: uppercase;
                    background: var(--primary-color);
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-style: normal;
                }
                .avatar--small {
                    width: 24px;
                    height: 24px;
                }
                .avatar--initials {
                    padding-bottom: 2px;
                }
            }
            .comment__details {
                flex: 1;
                flex-wrap: wrap;
                min-width: 0;
                line-height: 15px;
                max-width: 65%;
                .comment__author {
                    overflow: hidden;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: var(--primary-text-color);
                    height: 20px;
                    margin-bottom: -1px;
                }
                .comment__date-area {
                    &__date {
                        flex: 1;
                        display: inline-block;
                        font-size: 12px;
                        color: var(--secondary-text-color);
                        margin-right: 8px;
                        margin-bottom: 6px;
                        .date-field {
                            position: relative;
                            font-weight: 400;
                            font-style: normal;
                        }
                        .dot-separator {
                            font-size: 11px;
                            position: relative;
                            top: -1px;
                            margin: 0px 5px;
                        }
                        .annotation-type-icon {
                            font-size: 14px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
                .comment__reply-count {
                    display: inline-block;
                    color: #666;
                    font-size: 12px;
                }
            }
            .comment__status {                
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 10px;
                font-weight: 400;
                font-style: normal;
                position: absolute;
                right: -4px;
                top: 4px;
                display: flex;
                &__item {
                    max-width: 120px;
                    flex: 1;
                    display: block;
                    padding: 2px 8px;
                    background: desaturate(darken($global-brand, 25%), 40%);
                    border-radius: 4px;
                    color: $global-white;
                }
            }
            .comment__options {
                // flex: 0 24px;
                margin: 0 0 0 10px;
                width: 24px;
                position: relative;
                right:-56px;
                top: -12px;
            }
            .comment__header-close {
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                box-flex: 0;
                -webkit-flex: 0 24px;
                -moz-flex: 0 24px;
                -ms-flex: 0 24px;
                flex: 0 24px;
                display: none;
                padding: 0 0 0 8px;
                line-height: 30px;
                text-align: right;
            }
        }
        &__body-wrapper {
            position: relative;
            display: flex;
            margin: 8px 4px 8px 48px;
            .comment__number {
                position: absolute;
                right: calc(100% + 4px);
                flex: 0 0 auto;
                width: 40px;
                padding: 8px 0 0 0;
                font-size: 11px;
                text-align: right;
                line-height: 13px;
                color: #767676;
            }
            .comment__body {
                flex: 0 0 auto;
                position: relative;
                overflow: hidden;
                width: 100%;
                padding: 6px 8px;
                border: 0;
                border-radius: 4px;
                color: $mrnda-video-editor-font-color;
                background: var(--primary-background-color);
                .comment-text {
                    display: inline;
                    white-space: pre-wrap;
                    color: var(--primary-text-color);
                    word-wrap: break-word;
                    white-space: pre-wrap;
                }
            }
        }
        &__metadata {
            position: relative;
            overflow: hidden;
            margin: 4px 8px 4px 48px;
            color: var(--secondary-text-color);
            word-wrap: break-word;
            display: flex;
            justify-content: space-between;
            &__time-range {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                font-style: normal;
                &__item {
                    flex: 1;
                }
            }
            // &__status {
            //     align-items: center;
            //     justify-content: center;
            //     display: inline-block;
            //     font-size: 11px;
            //     font-weight: 400;
            //     font-style: normal;
            //     &__item {
            //         flex: 1;
            //         display: block;
            //         padding: 2px 8px;
            //         background: desaturate(darken($global-brand, 25%), 40%);
            //         border-radius: 4px;
            //         color: $global-white;
            //     }
            // }
            &__replies {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                font-style: normal;
                &__item {
                    flex: 1;
                }
            }

            &__attachments {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                font-style: normal;
                &__item {
                    flex: 1;
                    span {
                        margin-left: 4px;
                    }
                }
            }
        }
        &__replies-list {
            margin: 16px 0 16px 16px;
            padding: 16px 4px 0 32px;
            border-top: 1px solid var(--border-color);
        }
        &__attachments-list{
            margin: 16px 0 0px 16px;
            padding: 16px 4px 0 32px;
            border-top: 1px solid var(--border-color);
        }
        &__attachments-collapsed{
            margin: 4px 0 0px 42px;
            padding: 0px 0px 0 32px;  
            color: var(--primary-text-color);
            opacity: 0.6;
            transition: all 0.3s;
            &:hover {
                opacity: 1;
            }          
        }
        .reply-form {
            // border-top: 1px solid #4a4a4a;
            margin: 16px 4px 0px 12px;

            &__body-wrapper {
                display: flex;
                // align-items: center;
                margin: 8px 0 16px;
                .reply-form__avatar {
                    // flex: 0 36px;
                    width: 40px;
                    justify-content: center;
                    margin-top: 4px;
                }
                .reply-form__body {
                    // flex: 1;
                    // background: #fff;
                    position: relative;
                    border: 0;
                    border-radius: 6px;
                    line-height: 16px;
                    font-size: 11px;
                    width: 90%;
                    color: var(--primary-text-color);
                    & > textarea {
                        background-color: var(--primary-background-color);
                        border: none;
                        color: var(--primary-text-color);
                        width: 100%;
                        height: 32px;
                        resize: none;
                        overflow: hidden auto;
                    }
                }
            }

            &__footer {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                margin: 0px 0px 0px 0px;
            }
            &__attachments-list{
                margin:  0px 0px 16px 36px;
            }
        }
    }
    .comment--selected {
        @extend %comment;
        // background: lighten($comment-background-color, 8);
        border: 1px solid var(--primary-color-shade-2);        
        &:hover {
            border: 1px solid var(--primary-color-shade-2);
            .threedots__label {
                color: var(--primary-color);
                opacity: 1;
            }
        }
    }
    .comment--group-selected {
        @extend %comment;       
        border: 1px solid var(--primary-color-shade-2);        
    }

    .threedots {
        display: flex;
        flex-direction: row;
        background: 0 0;
        color: var(--secondary-text-color);
        cursor: pointer;
        position: relative;
        width: 20px;
        text-transform: none;
        &__label {
            &:hover {
                color: var(--primary-color);
                opacity: 1;
            }

            opacity: 0;
            color: var(--primary-color);
            cursor: pointer;
        }
    }
}

/*Reply-Form document Editor*/
.mrnda-document-reply-form-container {
    display: none;
    .reply-form {
        // border-top: 1px solid #4a4a4a;
        margin: 16px 4px 0px 12px;

        &__body-wrapper {
            display: flex;
            align-items: center;
            margin: 8px 0 16px;
            .reply-form__avatar {
                flex: 0 36px;
                width: 40px;
                justify-content: center;
            }
            .reply-form__body {
                flex: 1;
                // background: #fff;
                position: relative;
                border: 0;
                border-radius: 6px;
                line-height: 16px;
                font-size: 11px;
                color: $mrnda-video-editor-font-color;
                & > textarea {
                    background-color: $mrnda-video-editor-element-color;
                    border: none;
                    color: $mrnda-video-editor-font-color;
                    width: 100%;
                    height: 32px;
                    resize: none;
                    overflow: hidden auto;
                }
            }
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            margin: 0px 0px 0px 36px;
        }
    }
}
.panel.panel-default.object-panel.selected .mrnda-document-reply-form-container {
    display: block;
}


// COMMON
.button-comment-form--primary {
    font-size: 12px;
    border-radius: 2px;
    height: 28px;
    line-height: 26px;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    color: #fff;
    border: 0;
    background-color:var(--primary-color);
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
    text-overflow: clip;
    min-width: 70px;
}

.button-comment-form--textual {
    position: relative;
    color: #fff;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    height: 32px;
    line-height: 30px;
    min-width: 68px;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    color: #2f609f;
    border: 0;
    background-color: transparent;
    min-width: initial;
    min-width: 90px;
}
.delete-button {
    border: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;    
    transition: opacity .3s ease, background-color .3s ease;
    // border-radius: $base-border-radius;
    background-color: var(--secondary-background-color);
    border-radius: 15px;
    color : var(--secondary-text-color);
    & > i{
        font-size: 16px;
    }
    &:hover {
      opacity: 1;
      background-color: rgba($global-black,.05);
    }

    &:focus {
      outline: none;
    }
  }

  .comment-delete-button{

    margin-left: 0;
    margin-right: 12px;
  }

  .comment-edit-button{
    margin-left: 12px;
    margin-right: 0px;
  }

  .edit-disabled {
    cursor: not-allowed;
  }





//Commentitem lite
.mrnda-comment-container {
    %comment-lite,
    .comment-lite {        
        font-size: 13px;
        line-height: 18px;
        background: var(--secondary-background-color);
        position: relative;
        opacity: 1;
        border-radius: 4px;        
        transition: max-height 0.3s ease-in;
        height: auto;
        padding: 4px 4px 6px 4px;
        border: 1px solid var(--border-color);
        margin-top: 4px;
        //TODO: Need to look into this, scrollbar visible momentarily.
        // overflow-y: auto;
        &:hover {
            cursor: pointer;
            border: 1px solid #444444;
        }
        &-selected {
            border: 1px solid var(--primary-color);
        }

        &__header {
            margin: 4px 8px 8px 12px;
            position: relative;
            justify-content: flex-start;
            flex-direction: row;
            display: flex;
            -webkit-box-direction: normal;

            .comment__avatar {
                flex: 0 36px;
                margin-top: 2px;
                .avatar {
                    position: relative;
                    border-radius: 50%;
                    font-size: 10px;
                    line-height: 30px;
                    text-align: center;
                    text-transform: uppercase;
                    background: var(--primary-color);
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-style: normal;
                }
                .avatar--small {
                    width: 24px;
                    height: 24px;
                }
                .avatar--initials {
                    padding-bottom: 2px;
                }
            }
            .comment__details {
                flex: 1;
                flex-wrap: wrap;
                min-width: 0;
                line-height: 15px;
                max-width: 60%;
                .comment__author {
                    overflow: hidden;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: var(--primary-text-color);
                    height: 20px;
                    margin-bottom: -1px;
                }
                .comment__date-area {
                    &__date {
                        flex: 1;
                        display: inline-block;
                        font-size: 12px;
                        color: var(--secondary-text-color);
                        margin-right: 8px;
                        .date-field {
                            position: relative;
                            font-weight: 400;
                            font-style: normal;
                        }
                        .dot-separator {
                            font-size: 11px;
                            position: relative;
                            top: -1px;
                            margin: 0px 5px;
                        }
                        .annotation-type-icon {
                            font-size: 14px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
                .comment__reply-count {
                    display: inline-block;
                    color: #666;
                    font-size: 12px;
                }
            }
            .comment__status {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 10px;
                font-weight: 400;
                font-style: normal;
                position: absolute;
                right: -4px;
                top: 4px;
                display: flex;
                &__item {
                    max-width: 120px;
                    flex: 1;
                    display: block;
                    padding: 2px 8px;
                    background: desaturate(darken($global-brand, 25%), 40%);
                    border-radius: 4px;
                    color: $global-white;
                }
            }
            .comment__options {
                // flex: 0 24px;
                margin: 0 0 0 10px;
                width: 24px;
                position: relative;
                right:-56px;
                top: -12px;
            }
            .comment__header-close {
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                box-flex: 0;
                -webkit-flex: 0 24px;
                -moz-flex: 0 24px;
                -ms-flex: 0 24px;
                flex: 0 24px;
                display: none;
                padding: 0 0 0 8px;
                line-height: 30px;
                text-align: right;
            }
        }
        &__body-wrapper {
            position: relative;
            display: flex;
            margin: 8px 4px 8px 12px;
            .comment__number {
                position: absolute;
                right: calc(100% + 4px);
                flex: 0 0 auto;
                width: 40px;
                padding: 8px 0 0 0;
                font-size: 11px;
                text-align: right;
                line-height: 13px;
                color: #767676;
            }
            .comment__body {
                flex: 0 0 auto;
                position: relative;
                overflow: hidden;
                width: 100%;
                padding: 6px 8px;
                border: 0;
                border-radius: 4px;
                color: $mrnda-video-editor-font-color;
                background: var(--primary-background-color);
                .comment-text {
                    display: inline;
                    white-space: pre-wrap;
                    color: var(--primary-text-color);
                    word-wrap: break-word;
                    white-space: pre-wrap;
                }
            }
        }
        &__metadata {
            position: relative;
            overflow: hidden;
            margin: 0px 8px 0px 12px;
            color: var(--secondary-text-color);
            word-wrap: break-word;
            display: flex;
            justify-content: space-between;
            &__time-range {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                font-style: normal;
                &__item {
                    flex: 1;
                }
            }
            // &__status {
            //     align-items: center;
            //     justify-content: center;
            //     display: inline-block;
            //     font-size: 11px;
            //     font-weight: 400;
            //     font-style: normal;
            //     &__item {
            //         flex: 1;
            //         display: block;
            //         padding: 2px 8px;
            //         background: desaturate(darken($global-brand, 25%), 40%);
            //         border-radius: 4px;
            //         color: $global-white;
            //     }
            // }
            &__replies {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                font-style: normal;
                &__item {
                    flex: 1;
                }
            }

            &__attachments {
                align-items: center;
                justify-content: center;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                font-style: normal;
                &__item {
                    flex: 1;
                    span {
                        margin-left: 4px;
                    }
                }
            }
        }
        &__replies-list {
            margin: 16px 0 16px 16px;
            padding: 16px 4px 0 32px;
            border-top: 1px solid var(--border-color);
        }
        &__attachments-list{
            margin: 16px 0 0px 16px;
            padding: 16px 4px 0 32px;
            border-top: 1px solid var(--border-color);
        }
        &__attachments-collapsed{
            margin: 4px 0 0px 42px;
            padding: 0px 0px 0 32px;  
            color: var(--primary-text-color);
            opacity: 0.6;
            transition: all 0.3s;
            &:hover {
                opacity: 1;
            }          
        }
        .reply-form {
            // border-top: 1px solid #4a4a4a;
            margin: 16px 4px 0px 12px;

            &__body-wrapper {
                display: flex;
                // align-items: center;
                margin: 8px 0 16px;
                .reply-form__avatar {
                    // flex: 0 36px;
                    width: 40px;
                    justify-content: center;
                    margin-top: 4px;
                }
                .reply-form__body {
                    // flex: 1;
                    // background: #fff;
                    position: relative;
                    border: 0;
                    border-radius: 6px;
                    line-height: 16px;
                    font-size: 11px;
                    width: 90%;
                    color: var(--primary-text-color);
                    & > textarea {
                        background-color: var(--primary-background-color);
                        border: none;
                        color: var(--primary-text-color);
                        width: 100%;
                        height: 32px;
                        resize: none;
                        overflow: hidden auto;
                    }
                }
            }

            &__footer {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                margin: 0px 0px 0px 0px;
            }
            &__attachments-list{
                margin:  0px 0px 16px 36px;
            }
        }
    }
    .comment--selected {
        @extend %comment;
        // background: lighten($comment-background-color, 8);
        border: 1px solid var(--primary-color-shade-2);        
        &:hover {
            border: 1px solid var(--primary-color-shade-2);
            .threedots__label {
                color: var(--primary-color);
                opacity: 1;
            }
        }
    }
    .comment--group-selected {
        @extend %comment;       
        border: 1px solid var(--primary-color-shade-2);        
    }

    .threedots {
        display: flex;
        flex-direction: row;
        background: 0 0;
        color: var(--secondary-text-color);
        cursor: pointer;
        position: relative;
        width: 20px;
        text-transform: none;
        &__label {
            &:hover {
                color: var(--primary-color);
                opacity: 1;
            }

            opacity: 0;
            color: var(--primary-color);
            cursor: pointer;
        }
    }
}

.metadata-icons-list{
    display: inline;
}

.multi-annotation {
    margin: 12px 6px 0px 48px;
    background: var(--primary-background-color);
    padding: 10px 8px;
    border-radius: 4px;
    & > span{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}