// .document-js .vjs-progress-control { display: none; }
// .document-js .vjs-control-bar { justify-content: flex-end;}

// $mrnda-document-editor-body-color: #292b2f;
// $mrnda-document-editor-element-color: #3f4346;
// $mrnda-document-editor-font-color: white;

$mrnda-document-editor-body-color: #f7f8f9;
$mrnda-document-editor-element-color: white;
$mrnda-document-editor-font-color: black;

@mixin neumorphic-card {
    // border-radius: 10px;
    background: $mrnda-document-editor-element-color;
    // box-shadow: -20px 12px 12px 2px #1d1f22, 3px -5px 20px #292b30;
}

.mrnda-document-compare {
    // padding: 20px 20px 20px 20px;
    height: calc(100% - 20px);
    background-color: var(--primary-background-color);
    &__container {
        top: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        // width: 1430px;
        // margin: 0px 20px 0px 0px;
        height: calc(100% - 0px);
        overflow: hidden;
    }
    &__toolbar {
        display: flex;
        padding: 15px;
        justify-content: space-around;
        border-bottom: 1px solid var(--border-color);
        background: var(--secondary-background-color);
        &__settings {
            display: flex;
            justify-self: center;
            margin: 0 8px;
            &--item {
                color: var(--secondary-text-color);
                font-size: 16px;
                margin: 0 15px 0 0px;
                &:hover {
                    color: var(--primary-color);
                    cursor: pointer;
                }
                &.active {
                    color: var(--primary-color);
                }
                .color-palette{
                    display: flex;
                    &__color-outer{
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        border: 1px solid transparent;
                        cursor: pointer;
                        &.selected{
                            border: 1px solid var(--primary-color-shade-2);
                        }
                    }
                    &__color-inner{
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        border: 1px solid #1d1e22;
                    }
                }
            }
            &__auto-compare {
                position: absolute;
                top: 55px;
                background:var(--secondary-background-color);
                padding: 12px;
                z-index: 998;
                margin-left: -40px;
                border-radius: 4px;
                box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.09);
                -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.09);
                -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.09);

                &__opacity {
                    &--range{
                        width: 100%;
                    }
                }
                &__colors {
                    .color-palette{
                        display: flex;
                        &__color-outer{
                            width: 22px;
                            height: 22px;
                            border-radius: 3px;
                            border: 1px solid transparent;
                            margin: 2px;
                            cursor: pointer;
                            &.selected{
                                border: 1px solid var(--primary-color-shade-2);
                            }
                        }
                        &__color-inner{
                            width: 20px;
                            height: 20px;
                            border-radius: 3px;
                            border: 1px solid #1d1e22;
                        }
                    }
                }
            }
        }
        &__header {
            justify-content: center;
            display: flex;
            flex: 1;
            &--text {
                font-size: 16px;
                margin: 0 4px 0 0;
            }
            &--version {
                font-size: 12px;
                margin: 2px 0 0 0;
            }
        }
        &__close {
            position: absolute;
            right: 20px;
        }
    }
    &__viewer-area {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;
        //margin: 4px;
        align-items: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

    &__viewer-space {
        width: 2px;
        height: 100%;    
        background-color: var(--control-border-color);
        position: absolute;
        margin-left: -4px;  
        left: 50%; 
        opacity: 0.4; 
        z-index: 1;
    }
}
.document-compare-viewer-page {    
    border: 2px solid #f3f3f3;

    &__selected {
    
    -webkit-box-shadow: 0px 5px 40px -15px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 40px -15px rgba(0,0,0,0.2);

}
}
.document-compare-viewer-pages-container{
    height: 100%;
    // margin-top: 24px;   
    transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1), transform-origin 0.6s;
}

.overlay-area {
    position: absolute;
}


.overlay-opacity-host{
    display: flex;
    &__main{
        width: 28px;
        height: 18px;
        border-radius: 4px;
        border: 1px solid var(--control-border-color);
        margin: 2px;
        background-color: var(--primary-background-color);
        cursor: pointer;
        display: flex;
        justify-content: center;
        &.selected{
            border: 1px solid var(--primary-color-shade-2);
        }
        &__label{
            margin-top: -2px;
            font-size: 12px;
            text-align: center;
            font-weight: 600;
        }
    }
    
}


.mrnda-document-compare__viewer-area {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.mrnda-document-compare__viewer-area::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.mrnda-document-compare-icon-stack{
    height: 16px;
    width: 20px;
    line-height: unset;
    vertical-align: top;

}
// .mrnda-hide-scrollbar {
//     overflow-y: scroll;
//     scrollbar-width: none; /* Firefox */
//     -ms-overflow-style: none;  /* Internet Explorer 10+ */
// }
// .mrnda-hide-scrollbar::-webkit-scrollbar { /* WebKit */
//     width: 0;
//     height: 0;
// }


.main-drawing-canvas {
    transition: opacity .3s;
}

.d-transparent{

    opacity: 0;

}