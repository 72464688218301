.mrnda-breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mrnda-breadcrumb__item {
  display: inline-block;
  padding-left: $base-spacing-unit*2;
  margin-right: $base-spacing-unit*1.5;
  position: relative;
  
  &:first-child {
    padding-left: 0;
    background: transparent;
    &:after{
       height: 0px;
       width: 0px;
    }
  }

  // &:last-child {
  //   @extend %typography--dark;
  // }

  &:after {
    //content: '\25BC';
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--secondary-text-color);
    padding: 0 1em;    
    cursor: pointer;
    opacity: 0.7;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
    height: 38px;
    background-repeat: no-repeat;
    background-image: url('../../assets/Images/Icons/supportive/icon-sup-breadcrumb.svg');
    background-size: 8px 12px;
    background-position: right 20px bottom 4px;
    filter: unquote("invert(var(--icon-mask))")  ;
  }


  & a {
    color: inherit;

    &:hover {
      @extend %typography--dark;
    }
  }
}

